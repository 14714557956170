import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { resetCloseModal } from "store/action/alerts";
import { createClient, updateClient } from "store/action/clientAction";
import Alert from "components/Alert";

const Client = ({
  createClient,
  updateClient,
  clientReducer,
  isUpdating,
  data,
  resetCloseModal,
}) => {
  const form = useRef(null);
  let close = useRef(null);

  const resetForms = () => {
    document.getElementById("newClientForm").reset();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formdata = new FormData(form.current);
    isUpdating
      ? updateClient(data.id, formdata, "formdata")
      : createClient(formdata, "formdata");
  };

  useEffect(() => {
    if (clientReducer.closeModal) {
      close.click();
      resetForms();
      resetCloseModal();
    }
  }, [clientReducer.closeModal, resetCloseModal]);

  return (
    <div>
      <Alert />
      <form
        id="newClientForm"
        ref={form}
        className="p-2"
        onSubmit={e => handleSubmit(e)}>
        <div className="form-group mt-2  ">
          <label htmlFor="name" className="font-weight-bold">
            Client Name :
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            defaultValue={data.name}
            placeholder="Client Name"
            name="name"
            required
          />
        </div>
        <div className="form-group mt-2  ">
          <label htmlFor="contact_number" className="font-weight-bold">
            Contact Number :
          </label>
          <input
            type="number"
            className="form-control"
            id="contact_number"
            defaultValue={data.contact_number}
            placeholder="Contact Number"
            name="contact_number"
          />
        </div>
        <div className="form-group mt-2  ">
          <label htmlFor="address" className="font-weight-bold">
            Address :
          </label>
          <input
            type="text"
            className="form-control"
            id="address"
            defaultValue={data.address}
            placeholder="Address"
            name="address"
          />
        </div>
        <div className="form-group mt-2  ">
          <label htmlFor="contact_person" className="font-weight-bold">
            Contact Person :
          </label>
          <input
            type="text"
            className="form-control"
            id="contact_person"
            defaultValue={data.contact_person}
            placeholder="Contact Person"
            name="contact_person"
          />
        </div>
        <div className="form-group mt-2  ">
          <label htmlFor="contact_person_number" className="font-weight-bold">
            Contact Person Number :
          </label>
          <input
            type="number"
            className="form-control"
            id="contact_person_number"
            defaultValue={data.contact_person_number}
            placeholder="Contact Person Number"
            name="contact_person_number"
          />
        </div>
        <div className="form-group ms-2  ">
          <label htmlFor="email" className="font-weight-bold">
            Email :
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            defaultValue={data.email}
            placeholder="Email"
            name="email"
          />
        </div>
        <div className="d-flex justify-content-center mt-2">
          <button type="submit" className="btn btn-primary">
            {clientReducer.loading ? (
              <>
                <i className="fa fa-spinner fa-spin" />
                <span> Processing...</span>
              </>
            ) : isUpdating ? (
              <span>Update Client</span>
            ) : (
              <span>Save Client</span>
            )}
          </button>
          <button
            type="button"
            className="btn btn-warning ms-2"
            data-bs-dismiss="modal"
            ref={button => (close = button)}
            onClick={() => resetForms()}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default connect(({ clientReducer }) => ({ clientReducer }), {
  createClient,
  updateClient,
  resetCloseModal,
})(Client);
