import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getExperiencedGuards } from "store/action/guardAction";
import dayjs from "dayjs";
import Grid from "components/DataTables";

const ShiftExperienceForm = ({
  getExperiencedGuards,
  guardReducer,
  shiftReducer,
  allocateShift,
}) => {
  const dayArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  useEffect(() => {
    const queryParam = {};
    queryParam["site_experience"] =
      shiftReducer.site_details[0] && shiftReducer.site_details[0].site_name;
    queryParam["free_guards"] = shiftReducer.date_array;
    queryParam["start_time"] = shiftReducer.time_array;
    queryParam["finish_time"] = shiftReducer.time_finish_array;
    if (!guardReducer.isExpGuardUpToDate) getExperiencedGuards(queryParam);
  }, [
    getExperiencedGuards,
    guardReducer.isExpGuardUpToDate,
    shiftReducer.site_details,
  ]);

  const allocateObjectShift = (id) => {
    let allocateRequest = {};
    allocateRequest["guard_id"] = id;
    allocateRequest["status"] = "assigned";
    allocateRequest["id"] = shiftReducer.eventsList;
    allocateShift(allocateRequest);
  };

  const sendWhatsapp = (data) => {
    const num = parseInt(data.phone);
    const msg = shiftReducer?.site_details.map(
      (obj) =>
        `%0aSite name: ${obj?.site_name},${obj?.postal_code}%0a Date: ${
          dayArray[dayjs(obj?.start_date).day()]
        },${dayjs(obj?.start_date).format("DD-MM-YYYY")}%0a
      ${obj?.time}`
    );

    const name = data.full_name;
    window.open(`https://wa.me/44${num}?text=Dear ${name} please confirm the below assigned shifts :%0a
  ${msg} %0a%0aKind regards ${sessionStorage
      .getItem("username")
      .substring(0, sessionStorage.getItem("username").lastIndexOf("@"))}.`);
  };

  const actionRenderer = ({ data }) => {
    return (
      <>
        <button
          className="btn btn-dark ms-2"
          onClick={() => {
            allocateObjectShift(data.id);
          }}
        >
          <i className="fa fa-user-plus">Allocate</i>
        </button>
        <button
          className="btn btn-success ms-2"
          onClick={() => {
            sendWhatsapp(data);
          }}
        >
          <i className="fa fa-whatsapp">Whatsapp</i>
        </button>
      </>
    );
  };

  const commentRenderer = ({ data }) => {
    return (
      data.comment && (
        <>
          <span
            className="comment-count"
            data-toggle="tooltip"
            title={data.comment}
          >
            <i className="fa fa-info-circle"></i>
          </span>
        </>
      )
    );
  };

  const guardColumns = {
    columnDefs: [
      {
        headerName: "Comments",
        cellRenderer: commentRenderer,
        width: 80,
      },
      {
        headerName: "Staff Name",
        field: "full_name",
        // cellRenderer: commentRenderer,
        sortable: true,
      },

      { headerName: "SIA Number", field: "sia_static_door" },
      { headerName: "SIA Expires", field: "sia_expiry", width: 190 },
      { headerName: "Work Locations", field: "work_locations" },
      { headerName: "Phone", field: "phone", width: 190 },
      { headerName: "Email", field: "email", width: 190, flex: 1 },
      {
        headerName: "Actions",
        width: 250,
        cellRenderer: actionRenderer,
        pinned: "right",
      },
    ],
  };

  return (
    <div className="">
      {guardReducer.expGuardList.length ? (
        <div className="card ">
          <div className="card-header">
            <h4 className=""> Employees Worked At This Site Before</h4>
          </div>
          <div className="card-body">
            <Grid
              columnDefs={guardColumns}
              rowData={guardReducer.expGuardList}
              dataRetrieved={!guardReducer.isRetrieving}
              hideExtras={true}
            ></Grid>
          </div>
        </div>
      ) : (
        <h4 className="mt-4 text-center">
          No Employees Worked On This Site Before
        </h4>
      )}
    </div>
  );
};

export default connect(({ guardReducer }) => ({ guardReducer }), {
  getExperiencedGuards,
})(ShiftExperienceForm);
