export const updateObject = (oldObject, updatedValues) => {
  return {
    ...oldObject,
    ...updatedValues,
  };
};

export const getError = res => {
  let error = "";
  for (let [, value] of Object.entries(res)) {
    if (Array.isArray(value) || typeof value === "string") {
      error = value;
      return error;
    } else if (typeof value === "object" && !Array.isArray(value)) {
      getError(value);
    }
  }
};
