import React from "react";
import Grid from "components/DataTables";

const SIA = ({ guards, isRetrieving, title }) => {
  const guardColumns = {
    columnDefs: [
      { headerName: "#", field: "id", sortable: true, width: 70 },
      {
        headerName: "Staff Name",
        field: "full_name",
        sortable: true,
        width: 200,
      },
      { headerName: "BRP Number", field: "brp_number", width: 120 },
      { headerName: "BRP Expires", field: "brp_expiry", width: 200 },
      { headerName: "Phone", field: "phone", width: 120, flex: 1 },
    ],
  };

  return (
    <div className="card w-100">
      <div className="card-header">
        <h4 className="text-center">{title}</h4>
      </div>
      <div className="card-body">
        <Grid
          columnDefs={guardColumns}
          rowData={guards}
          dataRetrieved={!isRetrieving}
        ></Grid>
      </div>
    </div>
  );
};

export default SIA;
