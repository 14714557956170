export function baseUrl() {
  if (process.env.NODE_ENV === "development")
    return "http://localhost:8000/api/";
  else return `${window.location.origin}/api/`;
}

export function headers(contentType) {
  const obj = {};
  if (contentType !== "formdata") obj["Content-Type"] = "application/json ";

  if (sessionStorage.getItem("login_token"))
    obj["authorization"] = getAuthToken();

  return obj;
}

function getAuthToken() {
  return "Token " + sessionStorage.getItem("login_token");
}
