import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getInvoiceReports } from "store/action/reports";
import { getAllSites } from "store/action/siteAction";
import { resetCloseModal } from "store/action/alerts";
import ReportGrid from "components/Report";
import ExportJsonExcel from "js-export-excel";
import dayjs from "dayjs";

const Invoice = ({
  getInvoiceReports,
  reportReducer,
  clientReducer,
  resetCloseModal,
  getAllSites,
  siteReducer,
}) => {
  const dayArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let dateFrom = null;
  let dateTo = null;
  let company = 0;
  const [changeDate, setChangeDate] = useState(false);
  useEffect(() => {
    if (!siteReducer.isSiteUpToDate) getAllSites();
  }, [getAllSites, siteReducer.isSiteUpToDate]);

  useEffect(() => {
    resetCloseModal();
  }, [resetCloseModal]);

  const filterReports = () => {
    let params = {};
    if ((dateFrom && dateTo) || company) {
      params = {
        from_dt: dateFrom,
        to_dt: dateTo,
        client: clientReducer.isClientUpToDate && clientReducer.clientId,
      };
      if (company > 0) params["site"] = company;
      getInvoiceReports(params);
      setChangeDate(true);
    }
  };

  const exportAll = () => {
    let data = reportReducer.invoiceList.data;
    let option = {};
    let dataTable = [];
    let empTotal = [];
    if (data.length > 0) {
      if (data) {
        dataTable.push([
          `INVOICE FOR THE PERIOD:${
            changeDate
              ? ` ${dayjs(reportReducer?.invoiceList?.from_date).format(
                  "DD-MM-YYYY"
                )} To ${dayjs(reportReducer?.invoiceList?.to_date).format(
                  "DD-MM-YYYY"
                )}`
              : " For Current Week"
          }`,
        ]);
        data.forEach((object) => {
          let detailsArray = [];
          let obj = ["Site Name", object.site_name];

          object.details.forEach((details) => {
            let detailsObj = {
              "Employee Name": details.guard,
              "Day ": dayArray[dayjs(details.start_date).day()],
              "Start Date": dayjs(details.start_date).format("DD-MM-YYYY"),
              "Time ":
                details.scheduled_start_time +
                " To " +
                details.scheduled_finish_time,
              "Hours ": details.total_hours,
              "Charge Rate": object.charge_rate,
              "pay ": details.pay,
            };
            detailsArray.push(detailsObj);
          });

          empTotal = [
            " ",
            " ",
            " ",
            "Sub-Totals",
            object.total_hours,
            "",
            object.total_pay,
          ];
          dataTable.push({});
          dataTable.push(obj);
          dataTable.push({});
          dataTable.push([
            "Employee Name",
            "Day",
            "Date ",
            "Time ",
            "Hours (hrs)",
            "Charge Rate (£)",
            "pay (£)",
          ]);
          detailsArray.map((detailObj) => dataTable.push(detailObj));
          dataTable.push({});
          dataTable.push(empTotal);
          dataTable.push({});
        });
      }
    }
    option.fileName = `Invoice-Details-${new Date()}`;
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: "sheet",
      },
    ];

    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };
  useEffect(() => {
    const params = {};
    if (clientReducer.clientId) {
      params["client"] = clientReducer.clientId;
    }
    if (!reportReducer.isInvoiceUpToDate) getInvoiceReports(params);
  }, [reportReducer.isInvoiceUpToDate, getInvoiceReports]);

  useEffect(() => {
    if (!clientReducer.isClientUpToDate)
      getInvoiceReports({ client: clientReducer.clientId });
  }, [
    getInvoiceReports,
    clientReducer.isClientUpToDate,
    clientReducer.clientId,
  ]);
  return (
    <div className="col-12 border">
      <div className=" text-center card-header">
        <h3>Invoice Section</h3>
        <h6>
          By default this report fetches the invoice for current week. If you
          want invoice for other dates/clients you can use filters provided
          below
        </h6>
      </div>

      <div className="input-group mt-4 d-flex ">
        <div className="ms-2">
          <label htmlFor="dob" className="font-weight-bold">
            <b> Date From :</b>
          </label>
          <input
            type="date"
            className="form-control"
            id="date_from"
            placeholder="Date From"
            onChange={(e) => (dateFrom = e.target.value)}
            name="date_from"
          />
        </div>
        <div className="ms-2">
          <label htmlFor="dob" className="font-weight-bold">
            <b>Date To :</b>
          </label>
          <input
            type="date"
            className="form-control"
            id="date_to"
            placeholder="Date To"
            onChange={(e) => (dateTo = e.target.value)}
            name="date_to"
          />
        </div>
        <div className="form-group ms-2  ">
          <label htmlFor="name" className="font-weight-bold">
            Company/Site name<span className="req">*</span> :
          </label>
          <select
            type="text"
            className="form-control"
            id="company"
            onChange={(e) => (company = e.target.value)}
            placeholder="Company/Site"
            name="company"
            required
          >
            <option value="0">--Select From The List--</option>
            {siteReducer.siteList &&
              siteReducer.siteList.map((obj) => {
                return (
                  <>
                    <option value={obj.id} key={obj.id} label={obj.site_name}>
                      {obj.site_name}
                    </option>
                  </>
                );
              })}
          </select>
        </div>
        <div className="mt-4 ms-2">
          <button
            className="btn btn-primary text-white mt-1"
            onClick={() => {
              filterReports();
            }}
          >
            {reportReducer.loading ? (
              <>
                <i className="fa fa-spinner fa-spin" />
                <span> Processing...</span>
              </>
            ) : (
              <span>Generate Report</span>
            )}
          </button>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center card-header mt-2">
        <h6>
          Total Pay
          {changeDate
            ? ` For period ${dayjs(
                reportReducer?.invoiceList?.from_date
              ).format("DD-MM-YYYY")} To ${dayjs(
                reportReducer?.invoiceList?.to_date
              ).format("DD-M-YYYY")}`
            : " For Current Week"}{" "}
          For {reportReducer?.invoiceList?.hrs} hrs Is{" "}
          <i className="fa fa-gbp"></i> {reportReducer?.invoiceList?.pay}. The
          Invoice Has {reportReducer?.invoiceList?.staff_count} Staff Members
        </h6>
      </div>
      <div className="mt-4">
        <ReportGrid
          reportReducer={reportReducer}
          isInvoice={true}
          exportAll={exportAll}
        />
      </div>
    </div>
  );
};
export default connect(
  ({ reportReducer, clientReducer, siteReducer }) => ({
    reportReducer,
    clientReducer,
    siteReducer,
  }),
  {
    getInvoiceReports,
    getAllSites,
    resetCloseModal,
  }
)(Invoice);
