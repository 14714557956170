import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBookonReport } from "store/action/reports";

const BookonReport = () => {
  let [dateFrom, setDateFrom] = useState(null);
  let [dateTo, setDateTo] = useState(null);
  let [error, setError] = useState(false);
  const reportReducer = useSelector((state) => state.reportReducer);
  const clientReducer = useSelector((state) => state.clientReducer);
  const dispatch = useDispatch();

  const filterReports = (e) => {
    if (dateFrom && dateTo) {
      let params = {
        from_dt: dateFrom,
        to_dt: dateTo,
      };
      if (clientReducer?.clientId) {
        params["client"] = clientReducer?.clientId;
      }
      dispatch(getBookonReport(params));
      setTimeout(() => {
        document.querySelector("#date_from").value = "";
        document.querySelector("#date_to").value = "";
        setDateFrom(null);
        setDateTo(null);
      }, 100);
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
    }
  };

  return (
    <div className="col-12 border">
      <div className=" text-center card-header">
        <h3>Bookons</h3>
        <h6>
          Please select date range and client if required and click on export to
          download
        </h6>
        {error && (
          <span className="text-danger text-center">
            Denied! Please select range first!
          </span>
        )}
      </div>
      <div className="input-group mt-4 d-flex justify-content-center align-items-center">
        <div className="ms-2">
          <label htmlFor="dob" className="font-weight-bold">
            <b> Date From :</b>
          </label>
          <input
            type="date"
            className="form-control"
            id="date_from"
            placeholder="Date From"
            onChange={(e) => setDateFrom(e.target.value)}
            name="date_from"
          />
        </div>
        <div className="ms-2">
          <label htmlFor="dob" className="font-weight-bold">
            <b>Date To :</b>
          </label>
          <input
            type="date"
            className="form-control"
            id="date_to"
            placeholder="Date To"
            onChange={(e) => setDateTo(e.target.value)}
            name="date_to"
          />
        </div>
        <div className="mt-4 ms-2">
          <button
            className={`btn text-white mt-1 btn-primary`}
            onClick={() => {
              filterReports();
            }}
            disabled={error}
          >
            {reportReducer.loading ? (
              <>
                <i className="fa fa-spinner fa-spin" />
                <span> Downloading...</span>
              </>
            ) : (
              <span>{"Export"}</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default BookonReport;
