import Service from "api/service";

export const getAllSites =
  (queryParams, action = "GET_ALL_SITES") =>
  dispatch => {
    Service.get(
      dispatch,
      "sites/",
      {
        start: action,
        success: action + "_SUCCESS",
        failure: action + "_FAILED",
      },
      queryParams
    );
  };

export const createSite = (body, contentType) => dispatch => {
  Service.post(
    dispatch,
    "sites/",
    body,
    {
      start: "CREATE_SITE",
      success: "CREATE_SITE_SUCCESS",
      failure: "CREATE_SITE_FAILED",
    },
    contentType
  );
};

export const updateSite = (id, body, contentType) => dispatch => {
  Service.patch(
    dispatch,
    `sites/${id}/`,
    body,
    {
      start: "UPDATE_SITE",
      success: "UPDATE_SITE_SUCCESS",
      failure: "UPDATE_SITE_FAILED",
    },
    contentType
  );
};

export const deleteSite = id => dispatch => {
  Service.delete(dispatch, `sites/${id}/`, {
    start: "DELETE_SITE",
    success: "DELETE_SITE_SUCCESS",
    failure: "DELETE_SITE_FAILED",
  });
};
