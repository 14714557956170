import { combineReducers } from "redux";
import userReducer from "./userReducer";
import guardReducer from "./guardReducer";
import shiftReducer from "./shiftReducer";
import siteReducer from "./siteReducer";
import alerts from "./alerts";
import clientReducer from "./clientReducer";
import reportReducer from "./reportReducer";
import chatReducer from "./chatReducer";

const appReducer = combineReducers({
  // bringin your reducers here
  alerts,
  userReducer,
  guardReducer,
  shiftReducer,
  reportReducer,
  siteReducer,
  clientReducer,
  chatReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT_SUCCESS") state = undefined;

  return appReducer(state, action);
};

export default rootReducer;
