import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllGuards } from "store/action/guardAction";
import { getAllShifts } from "store/action/shiftAction";
import Stats from "./stats";
import ScheduledShifts from "./scheduledShifts";
import Expired from "./sia";
import BrpExpire from "./brp";

const Dashboard = ({
  shiftReducer,
  guardReducer,
  getAllGuards,
  getAllShifts,
  clientReducer,
}) => {
  useEffect(() => {
    const queryParams = { sia_expired: true };
    getAllGuards(queryParams, "GET_SIA_EXPIRED_GUARDS");
  }, [getAllGuards]);

  useEffect(() => {
    const queryParams = { sia_expiring: true };
    getAllGuards(queryParams, "GET_SIA_EXPIRING_GUARDS");
  }, [getAllGuards]);

  useEffect(() => {
    getAllShifts(
      { today: true, client: clientReducer.clientId },
      "GET_TODAY_SHIFTS"
    );
  }, [
    getAllGuards,
    getAllShifts,
    clientReducer.isClientUpToDate,
    clientReducer.clientId,
  ]);

  useEffect(() => {
    const queryParams = { brp_expired: true };
    getAllGuards(queryParams, "GET_BRP_EXPIRED_GUARDS");
  }, [getAllGuards]);

  useEffect(() => {
    const queryParams = { brp_expiring: true };
    getAllGuards(queryParams, "GET_BRP_EXPIRING_GUARDS");
  }, [getAllGuards]);

  return (
    <div className="d-flex flex-column col-12 border">
      <div className="text-center mt-3 mb-3 card-header">
        <h3>Dashboard Section</h3>
      </div>
      <Stats stats={shiftReducer.stats} />
      <ScheduledShifts
        data={shiftReducer.todayShifts}
        isRetrieving={shiftReducer.fetchingTodayShifts}
        stats={shiftReducer.stats}
      />
      <div className="d-flex justify-content-between flex-column flex-md-row flex-gap mt-5">
        <Expired
          guards={guardReducer.siaExpiredGuards}
          isRetrieving={guardReducer.fetchingSIAExpired}
          title={"SIA Expired"}
        />
        <Expired
          guards={guardReducer.siaExpiringGuards}
          isRetrieving={guardReducer.fetchingSIAExpiring}
          title={"SIA Expiring in next 30 days"}
        />
      </div>
      <div className="d-flex justify-content-between flex-column flex-md-row flex-gap mt-5">
        <BrpExpire
          guards={guardReducer.brpExpiredGuards}
          isRetrieving={guardReducer.fetchingBRPExpired}
          title={"BRP Expired"}
        />
        <BrpExpire
          guards={guardReducer.brpExpiringGuards}
          isRetrieving={guardReducer.fetchingBRPExpiring}
          title={"BRP Expiring in next 30 days"}
        />
      </div>
    </div>
  );
};

export default connect(
  ({ shiftReducer, guardReducer, clientReducer }) => ({
    shiftReducer,
    guardReducer,
    clientReducer,
  }),
  {
    getAllGuards,
    getAllShifts,
  }
)(Dashboard);
