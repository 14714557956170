import Alert from "components/Alert";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getBookonShifts, resetBookonData } from "store/action/shiftAction";
import HourlyCheckBoxes from "./Checkbox";

const AllBookon = ({
  getBookonShifts,

  resetBookonData,
  shiftReducer,
}) => {
  let [dateFrom, setDateFrom] = useState(null);
  let [dateTo, setDateTo] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [filteredBookonShifts, setFilteredBookonShifts] = useState(
    shiftReducer.bookonShiftList
  );

  const fetchBookonShifts = () => {
    const params = {
      bookon: "all",
    };
    if (dateFrom && dateTo) {
      params["from_dt"] = dateFrom;
      params["to_dt"] = dateTo;
    }
    getBookonShifts(params);
  };

  useEffect(() => {
    if (!shiftReducer.isBookonShiftListUpToList) fetchBookonShifts();
  }, [shiftReducer.isBookonShiftListUpToList]);

  useEffect(() => {
    return () => {
      resetBookonData();
    };
  }, []);

  useEffect(() => {
    if (filterText !== "") {
      setFilteredBookonShifts(
        shiftReducer.bookonShiftList?.filter((s) =>
          s.site_name.toLowerCase().includes(filterText.toLowerCase())
        )
      );
    } else {
      setFilteredBookonShifts(shiftReducer.bookonShiftList);
    }
  }, [filterText, shiftReducer.bookonShiftList]);

  return (
    <div className="col-12">
      <Alert />
      <div className="input-group mt-4 d-flex justify-content-center align-items-center">
        <div className="ms-2">
          <label htmlFor="dob" className="font-weight-bold">
            <b> Date From :</b>
          </label>
          <input
            type="date"
            className="form-control"
            id="date_from"
            placeholder="Date From"
            onChange={(e) => setDateFrom(e.target.value)}
            name="date_from"
          />
        </div>
        <div className="ms-2">
          <label htmlFor="dob" className="font-weight-bold">
            <b>Date To :</b>
          </label>
          <input
            type="date"
            className="form-control"
            id="date_to"
            placeholder="Date To"
            onChange={(e) => setDateTo(e.target.value)}
            name="date_to"
          />
        </div>
        <div className="mt-4 ms-2">
          <button
            className={`btn text-white mt-1 btn-primary`}
            onClick={() => {
              fetchBookonShifts();
            }}
          >
            {shiftReducer.loading ? (
              <>
                <i className="fa fa-spinner fa-spin" />
                <span> Downloading...</span>
              </>
            ) : (
              <span>{"Submit"}</span>
            )}
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-evenly w-100 m-2">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="site name to search..."
            type="text"
            onChange={(e) => {
              setFilterText(e.target.value);
            }}
          />
        </div>
        <strong>
          Displaying confirmed shifts{" "}
          {dateFrom && dateTo
            ? `from ${dateFrom} to ${dateTo}`
            : "of yesterday and today"}
        </strong>
        <button
          className="btn btn-primary mb-2"
          onClick={(e) => {
            fetchBookonShifts();
          }}
        >
          Refresh shifts
        </button>
      </div>
      <table className="table table-striped table-bordered">
        <thead className="">
          <tr>
            <th>Site Name</th>
            <th>Officer Name</th>
            <th>Shift Date</th>
            <th>Shift Times</th>
            <th>Actual Times</th>
            <th>Check calls</th>
            <th>Bookon</th>
            <th>Bookon By</th>
          </tr>
        </thead>
        <tbody>
          {filteredBookonShifts?.length ? (
            filteredBookonShifts.map((shift) => {
              return (
                <tr>
                  <td>
                    <div className="d-flex flex-column">
                      <span>
                        <strong>{shift.site_name}</strong>
                      </span>
                      <span>Postal code {shift.postal_code}</span>
                      <span>Total hours {shift.total_hours}</span>
                      <span>Actual hours {shift.actual_total_hours}</span>
                    </div>
                  </td>
                  <td>
                    {shift.guard} ({shift.guard_pin || "-"})
                  </td>
                  <td>{shift.start_date}</td>
                  <td>
                    {shift.scheduled_start_time} - {shift.scheduled_finish_time}
                  </td>
                  <td>
                    {shift.actual_start_time} - {shift.actual_finish_time}
                  </td>
                  <td>
                    {shift.check_calls_needed ? (
                      <div>
                        <HourlyCheckBoxes
                          startTime={shift.scheduled_start_time}
                          endTime={shift.scheduled_finish_time}
                          shift={shift.id}
                          check_calls={shift.check_calls}
                          start_date={shift.start_date}
                        />
                      </div>
                    ) : (
                      <p>Not enabled</p>
                    )}
                  </td>
                  <td>{shift.bookon}</td>
                  <td>{shift.bookon_by}</td>
                </tr>
              );
            })
          ) : (
            <p>No records to display</p>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default connect(({ shiftReducer }) => ({ shiftReducer }), {
  getBookonShifts,
  resetBookonData,
})(AllBookon);
