import Service from "api/service";

export const login = body => dispatch => {
  Service.post(dispatch, "login/", body, {
    start: "USER_LOGIN",
    success: "USER_LOGIN_SUCCESS",
    failure: "USER_LOGIN_FAILED",
  });
};

export const logoutAction = () => {
  return {
    type: "USER_LOGOUT_SUCCESS",
  };
};

export const getAllUsers = () => dispatch => {
  Service.get(dispatch, "users/", {
    start: "GET_USER_LIST",
    success: "GET_USER_LIST_SUCCESS",
    failure: "GET_USER_LIST_FAILED",
  });
};

export const getRoles = () => dispatch => {
  Service.get(dispatch, "roles/", {
    start: "GET_ROLES_LIST",
    success: "GET_ROLES_LIST_SUCCESS",
    failure: "GET_ROLES_LIST_FAILED",
  });
};

export const createUser = (body, contentType) => dispatch => {
  Service.post(
    dispatch,
    "users/",
    body,
    {
      start: "ADD_USER",
      success: "ADD_USER_SUCCESS",
      failure: "ADD_USER_FAILED",
    },
    contentType
  );
};

export const deleteUser = id => dispatch => {
  Service.delete(dispatch, `users/${id}/`, {
    start: "DELETE_USER",
    success: "DELETE_USER_SUCCESS",
    failure: "DELETE_USER_FAILED",
  });
};

export const updateUser = (id, body, contentType) => dispatch => {
  Service.patch(
    dispatch,
    `users/${id}/`,
    body,
    {
      start: "UPDATE_USER",
      success: "UPDATE_USER_SUCCESS",
      failure: "UPDATE_USER_FAILED",
    },
    contentType
  );
};

export const forgotPassword = email => dispatch => {
  Service.post(dispatch, "send-token/", email, {
    start: "SEND_TOKEN",
    success: "SEND_TOKEN_SUCCESS",
    failure: "SEND_TOKEN_FAILED",
  });
};

export const changePassword = body => dispatch => {
  Service.patch(dispatch, "change-password/", body, {
    start: "CHANGE_PASSWORD",
    success: "CHANGE_PASSWORD_SUCCESS",
    failure: "CHANGE_PASSWORD_FAILED",
  });
};
