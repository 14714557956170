import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import ShiftForm from "./shiftForm";
import {
  getAllShifts,
  allocateShift,
  deleteShift,
  setEvents,
} from "store/action/shiftAction";
import { connect } from "react-redux";
import ShiftGrid from "components/ShiftGrid";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Alert from "components/Alert";

const Shifts = ({
  getAllShifts,
  shiftReducer,
  allocateShift,
  deleteShift,
  status,
  setEvents,
  clientReducer,
  location,
}) => {
  const [currentDate, setCurrentDate] = useState(
    location?.state?.dateToOpen ? dayjs(location.state.dateToOpen) : dayjs()
  );
  const [mode, setMode] = useState(6);
  const [calendarView, setcalendarView] = useState(false);
  const dayArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [filterText, setFilterText] = useState("");
  useEffect(() => {
    let params = {
      start_date: currentDate.format("YYYY-MM-DD"),
      mode: mode,
      status: status && status,
    };
    if (clientReducer.clientId) {
      params["client"] = clientReducer.clientId;
    }
    if (!shiftReducer.isShiftUpToDate) getAllShifts(params);
    setEvents([]);
  }, [
    getAllShifts,
    shiftReducer.isShiftUpToDate,
    currentDate,
    mode,
    setEvents,
    status,
    clientReducer.isClientUpToDate,
    clientReducer.clientId,
  ]);

  useEffect(() => {
    let params = {
      start_date: currentDate.format("YYYY-MM-DD"),
      mode: mode,
      status: status && status,
    };
    if (clientReducer.clientId) {
      params["client"] = clientReducer.clientId;
    }
    getAllShifts(params);
  }, [
    currentDate,
    status,
    getAllShifts,
    clientReducer.isClientUpToDate,
    clientReducer.clientId,
  ]);

  useEffect(() => {
    if (mode === 30) {
      setCurrentDate(currentDate.date(1));
    } else {
      setCurrentDate(
        dayjs(
          location?.state?.dateToOpen
            ? dayjs(location.state.dateToOpen)
            : dayjs()
        )
      );
    }
    changeView();
  }, [mode]);

  const nextWeek = () => {
    let newDate = null;
    if (mode === 6) {
      newDate = currentDate.add(7, "day");
    } else {
      newDate = currentDate.add(
        currentDate.endOf("month").date() - 2 - currentDate.day(),
        "day"
      );
    }
    setCurrentDate(newDate);
  };

  const prevWeek = () => {
    let newDate = null;
    if (mode === 6) {
      newDate = currentDate.subtract(7, "day");
    } else {
      newDate = currentDate.subtract(
        currentDate.endOf("month").date() + currentDate.day() - 1,
        "day"
      );
    }
    setCurrentDate(newDate);
  };

  const changeView = () => {
    setcalendarView(!calendarView);
  };

  const confirmShifts = (cancel) => {
    let allocateRequest = {};
    // if (cancel) allocateRequest["guard_id"] = null;
    allocateRequest["status"] = cancel ? "cancelled" : "confirmed";
    allocateRequest["id"] = shiftReducer.eventsList;
    allocateShift(allocateRequest);
  };

  const unConfirmShifts = (unconfirm) => {
    let allocateRequest = {};
    if (!unconfirm) allocateRequest["guard_id"] = null;
    allocateRequest["status"] = unconfirm ? "assigned" : "unassigned";
    allocateRequest["id"] = shiftReducer.eventsList;
    allocateShift(allocateRequest);
  };

  const deleteShifts = () => {
    let allocateRequest = {};
    allocateRequest["id"] = shiftReducer.eventsList;
    deleteShift(allocateRequest);
  };

  return (
    <div className="col-12 card-body border">
      <Alert />
      <div className="shift-header">
        <div className="d-flex justify-content-between border card-header  mb-2">
          <h3 className="search-box d-flex jystify-content-space-between ms-2 mt-2">
            Site View{" "}
            <div className="form-group">
              <input
                className="form-control"
                placeholder="site name to search..."
                type="text"
                onChange={(e) => {
                  setFilterText(e.target.value);
                }}
              />
            </div>
          </h3>
          <Link
            to="/staff-view-shifts"
            state={{ status: status }}
            disabled={status === "unassigned"}
            hidden={status === "unassigned"}
            className="me-2 mt-2 mb-2 btn btn-primary"
          >
            <i className="fa fa-eye"></i>
            {shiftReducer.loading ? (
              <>
                <i className="fa fa-spinner fa-spin" />
                <span> Processing...</span>
              </>
            ) : (
              <span className="text-white">view staff wise</span>
            )}
          </Link>
        </div>
        <div className="headerClass d-flex justify-content-between">
          <div>
            <button
              className="btn btn-primary changeDateButton me-2"
              onClick={() => prevWeek()}
            >
              <i className="fa fa-arrow-left"></i>
            </button>
            <button
              className="btn btn-primary changeDateButton  me-2"
              onClick={() => nextWeek()}
            >
              <i className="fa fa-arrow-right"></i>
            </button>
            <button
              className="btn btn-primary  text-white "
              id="add-shift"
              data-bs-toggle="modal"
              data-bs-target="#commonModal"
            >
              Add Shift
            </button>
          </div>
          <span className="headerDate">
            <b>
              {dayArray[currentDate.day()]} - {currentDate.format("DD-MM-YYYY")}
            </b>
          </span>
          <button
            className="btn btn-primary text-white "
            onClick={() => {
              mode === 6 ? setMode(30) : setMode(6);
            }}
          >
            {calendarView ? "Month View" : "Week View"}
          </button>
        </div>

        <div className="headerClass d-flex justify-content-between mt-3 ">
          {(!status || status === "unassigned" || status === "cancelled") && (
            <Link
              className="btn btn-second  me-2"
              to={{
                pathname:
                  shiftReducer.eventsList && shiftReducer.eventsList.length > 0
                    ? "/allocate-form"
                    : "",
              }}
            >
              <i className="fa fa-user-plus"></i>
              Allocate
            </Link>
          )}

          {(!status || !status === "unassigned" || status === "assigned") && (
            <button
              className="btn btn-primary  me-2"
              onClick={() => unConfirmShifts(false)}
              disabled={
                !(shiftReducer.eventsList && shiftReducer.eventsList.length > 0)
              }
            >
              <i className="fa fa-minus"></i>
              {shiftReducer.isRetrieving ? (
                <>
                  <i className="fa fa-spinner fa-spin" />
                  <span> Processing...</span>
                </>
              ) : (
                <span>Un-Allocate</span>
              )}
            </button>
          )}
          {(!status || !status === "unassigned" || status === "assigned") && (
            <button
              className="btn btn-success  me-2"
              onClick={() => confirmShifts(false)}
              disabled={
                !(shiftReducer.eventsList && shiftReducer.eventsList.length > 0)
              }
            >
              <i className="fa fa-check"></i>
              {shiftReducer.isRetrieving ? (
                <>
                  <i className="fa fa-spinner fa-spin" />
                  <span> Processing...</span>
                </>
              ) : (
                <span>Confirm</span>
              )}
            </button>
          )}
          {(!status || !status === "unassigned" || status === "confirmed") && (
            <button
              className="btn btn-allocate  me-2"
              onClick={() => unConfirmShifts(true)}
              disabled={
                !(shiftReducer.eventsList && shiftReducer.eventsList.length > 0)
              }
            >
              <i className="fa fa-user-plus"></i>
              {shiftReducer.isRetrieving ? (
                <>
                  <i className="fa fa-spinner fa-spin" />
                  <span> Processing...</span>
                </>
              ) : (
                <span>Un-Confirm</span>
              )}
            </button>
          )}
          {(!status || !status === "unassigned" || status === "confirmed") && (
            <button
              className="btn btn-danger  me-2"
              onClick={() => confirmShifts(true)}
              disabled={
                !(shiftReducer.eventsList && shiftReducer.eventsList.length > 0)
              }
            >
              <i className="fa fa-times"></i>
              {shiftReducer.isRetrieving ? (
                <>
                  <i className="fa fa-spinner fa-spin" />
                  <span> Processing...</span>
                </>
              ) : (
                <span>Cancel</span>
              )}
            </button>
          )}
          <button
            className="btn btn-second  me-2"
            onClick={() => deleteShifts()}
            disabled={
              !(shiftReducer.eventsList && shiftReducer.eventsList.length > 0)
            }
          >
            <i className="fa fa-trash"></i>
            {shiftReducer.isRetrieving ? (
              <>
                <i className="fa fa-spinner fa-spin" />
                <span> Processing...</span>
              </>
            ) : (
              <span>Delete</span>
            )}
          </button>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <h4 className="text-center mt-2 card-header ">
          You can allocate one shift for an employee per day
        </h4>
        <ShiftGrid
          currentDate={currentDate}
          mode={mode}
          filterText={filterText}
        />
      </div>

      <Modal title="Add New Shift">
        <ShiftForm shiftReducer={{}} />
      </Modal>
    </div>
  );
};

export default connect(
  ({ shiftReducer, clientReducer }) => ({ shiftReducer, clientReducer }),
  {
    getAllShifts,
    allocateShift,
    setEvents,
    deleteShift,
  }
)(Shifts);
