import React from "react";
import "./App.scss";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Dashboard from "screens/Dashboard";
import ProtectedRoute from "components/ProtetedRoutes";
import Login from "screens/Login/login";
import Guard from "screens/Guard";
import Shifts from "screens/Shifts";
import Site from "screens/Site";
import StaffShifts from "screens/Shifts/staffView";
import Clients from "screens/Clients";
import AllocateForm from "screens/Shifts/allocateForm";
import AssignedShift from "screens/AssignedShifts";
import ConfrimedShifts from "screens/ConfirmedShifts";
import ForgotPassword from "screens/ForgotPassword";
import User from "screens/Users/user";
import CancelledShifts from "screens/CancelledShifts";
import ResetPassword from "screens/ResetPassword";
import UnAssignedShifts from "screens/UnAssigned/index";
import ShiftForm from "screens/Shifts/shiftForm";
import ChangePassword from "screens/ChangePassword/changePassword";
import Communication from "screens/Communication";
import Payroll from "screens/Payroll";
import GuestPayroll from "screens/Payroll/guestPayroll";
import Invoice from "screens/Invoice";
import Rota from "screens/Rota";
import GuardDetails from "screens/Guard/guard-details";
import Bookon from "screens/Bookon";
import ConfirmedBookon from "screens/Bookon/confirmed-bookon";
import BookReport from "screens/Bookon/Reports/bookon";
import AllBookon from "screens/Bookon/allBookon";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/change-password" element={<ChangePassword />} />
          <Route
            exact
            path={`/reset-password/:token`}
            element={<ResetPassword />}
          />

          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/employees" element={<Guard />} />
            <Route path="/all-shifts" element={<Shifts />} />
            <Route path="/sites" element={<Site />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/allocate-form" element={<AllocateForm />} />
            <Route path="/assigned" element={<AssignedShift />} />
            <Route path="/confirmed" element={<ConfrimedShifts />} />
            <Route path="/cancelled" element={<CancelledShifts />} />
            <Route path="/bookon" element={<Bookon />} />
            <Route path="/all-bookon" element={<AllBookon />} />
            <Route path="/confirmed-bookon" element={<ConfirmedBookon />} />
            <Route path="/unassigned" element={<UnAssignedShifts />} />
            <Route path="/staff-view-shifts" element={<StaffShifts />} />
            <Route path="/update-shift" element={<ShiftForm />} />
            <Route path="/chats" element={<Communication />} />
            <Route path="/payroll" element={<Payroll />} />
            <Route path="/rota" element={<Rota />} />
            <Route path="/guard-details" element={<GuardDetails />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/users" element={<User />} />
            <Route path="/guest-payroll" element={<GuestPayroll />} />
            <Route path="/bookon-report" element={<BookReport />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
