import { updateObject } from "utils/utility";
import { getError } from "utils/utility";

const initialState = {
  isRetrieving: false,
  payrollList: [],
  guestPayrollList: [],
  isReportUpToDate: false,
  isInvoiceUpToDate: false,
  loading: false,
  error: "",
  invoiceList: [],
};

export default function guardReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_PAYROLL":
      return updateObject(state, {
        loading: true,
        isRetrieving: true,
        error: "",
        payrollList: [],
      });
    case "GET_PAYROLL_SUCCESS":
      return updateObject(state, {
        isReportUpToDate: true,
        isRetrieving: false,
        isInvoiceUpToDate: false,
        loading: false,
        payrollList: action.response,
        error: "",
      });
    case "GET_PAYROLL_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        isInvoiceUpToDate: false,
        loading: false,
        isReportUpToDate: false,
        error: getError(action.response),
      });
    case "GET_GUEST_PAYROLL":
      return updateObject(state, {
        loading: true,
        isRetrieving: true,
        error: "",
        guestPayrollList: [],
      });
    case "GET_GUEST_PAYROLL_SUCCESS":
      return updateObject(state, {
        isReportUpToDate: true,
        isRetrieving: false,
        isInvoiceUpToDate: false,
        loading: false,
        guestPayrollList: action.response,
        error: "",
      });
    case "GET_GUEST_PAYROLL_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        isInvoiceUpToDate: false,
        loading: false,
        isReportUpToDate: false,
        error: getError(action.response),
      });
    case "GET_INVOICE":
      return updateObject(state, {
        loading: true,
        isRetrieving: true,
        error: "",
        invoiceList: [],
      });
    case "GET_INVOICE_SUCCESS":
      return updateObject(state, {
        isReportUpToDate: false,
        isInvoiceUpToDate: true,
        isRetrieving: false,
        loading: false,
        invoiceList: action.response,
        error: "",
      });
    case "GET_INVOICE_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        isReportUpToDate: false,
        isInvoiceUpToDate: false,
        loading: false,
        error: getError(action.response),
      });
    case "GET_ROTA":
    case "GET_BOOKON_REPORT":
      return updateObject(state, {
        loading: true,
        isRetrieving: true,
        error: "",
      });
    case "GET_ROTA_SUCCESS":
    case "GET_BOOKON_REPORT_SUCCESS":
      return updateObject(state, {
        isReportUpToDate: false,
        isRetrieving: false,
        loading: false,
        error: "",
      });
    case "GET_ROTA_FAILED":
    case "GET_BOOKON_REPORT_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        isReportUpToDate: false,
        loading: false,
        error: getError(action.response),
      });
    case "RESET_CLOSE_MODAL":
      return updateObject(state, {
        isReportUpToDate: false,
        isInvoiceUpToDate: false,
      });
    case "CONNECTION_REFUSED":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
      });
    default:
      return state;
  }
}
