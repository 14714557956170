import { updateObject } from "utils/utility";
import { getError } from "utils/utility";

const initialState = {
  isGuardUpToDate: false,
  isExpGuardUpToDate: false,
  isFreeGuardUpToDate: false,
  closeModal: false,
  loading: false,
  isRetrieving: false,
  error: "",
  message: "",
  guardList: [],
  freeGuards: [],
  expGuardList: [],
};

export default function guardReducer(state = initialState, action) {
  switch (action.type) {
    case "CONNECTION_REFUSED":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
      });
    case "CREATE_GUARD":
      return updateObject(state, {
        loading: true,
        isRetrieving: true,
        error: "",
      });
    case "CREATE_GUARD_SUCCESS":
      return updateObject(state, {
        isGuardUpToDate: false,
        isRetrieving: false,
        loading: false,
        message: action.response,
        closeModal: true,
        error: "",
      });
    case "CREATE_GUARD_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
        error: getError(action.response),
      });
    case "SEND_EMAIL":
      return updateObject(state, {
        loading: true,
        isRetrieving: true,
        error: "",
      });
    case "SEND_EMAIL_SUCCESS":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
        closeModal: true,
        error: "",
      });
    case "SEND_EMAIL_FAILED":
      return updateObject(state, {
        loading: false,
        isRetrieving: false,
        closeModal: false,
        error: getError(action.response),
      });
    case "GET_ALL_GUARDS":
      return updateObject(state, { loading: true, error: "" });
    case "GET_ALL_GUARDS_SUCCESS":
      return updateObject(state, {
        isGuardUpToDate: true,
        loading: false,
        guardList: action.response,
      });
    case "GET_ALL_GUARDS_FAILED":
      return updateObject(state, {
        loading: false,
        error: getError(action.response),
      });
    case "GET_FREE_GUARDS":
      return updateObject(state, { loading: true, error: "" });
    case "GET_FREE_GUARDS_SUCCESS":
      return updateObject(state, {
        isFreeGuardUpToDate: true,
        freeGuards: action.response,
      });

    case "GET_FREE_GUARDS_FAILED":
      return updateObject(state, {
        error: getError(action.response),
      });
    case "GET_ALL_SHIFTS_SUCCESS":
      return updateObject(state, {
        isGuardUpToDate: false,
        isExpGuardUpToDate: false,
        isFreeGuardUpToDate: false,
      });

    case "GET_EXPERIENCED_GUARDS":
      return updateObject(state, { loading: true, error: "" });
    case "GET_EXPERIENCED_GUARDS_SUCCESS":
      return updateObject(state, {
        isExpGuardUpToDate: true,
        expGuardList: action.response,
        error: "",
      });
    case "GET_EXPERIENCED_GUARDS_FAILED":
      return updateObject(state, {
        error: getError(action.response),
      });
    case "DELETE_GUARD":
      return updateObject(state, { loading: true, error: "" });
    case "DELETE_GUARD_SUCCESS":
      return updateObject(state, {
        isGuardUpToDate: false,
        closeModal: true,
        error: "",
      });
    case "DELETE_GUARD_FAILED":
      return updateObject(state, {
        error: getError(action.response),
      });
    case "UPDATE_GUARD":
      return updateObject(state, {
        loading: true,
        error: "",
      });
    case "UPDATE_GUARD_SUCCESS":
      return updateObject(state, {
        isGuardUpToDate: false,
        closeModal: true,
        loading: false,
        error: "",
      });
    case "UPDATE_GUARD_FAILED":
      return updateObject(state, {
        loading: false,
        error: getError(action.response),
      });
    case "RESET_CLOSE_MODAL":
      return updateObject(state, {
        closeModal: false,
      });
    case "GET_SIA_EXPIRED_GUARDS":
      return updateObject(state, { fetchingSIAExpired: true, error: "" });
    case "GET_SIA_EXPIRED_GUARDS_SUCCESS":
      return updateObject(state, {
        siaExpiredGuards: action.response,
        error: "",
        fetchingSIAExpired: false,
      });

    case "GET_SIA_EXPIRED_GUARDS_FAILED":
      return updateObject(state, {
        error: getError(action.response),
        fetchingSIAExpired: false,
      });

    case "GET_SIA_EXPIRING_GUARDS":
      return updateObject(state, { fetchingSIAExpiring: true, error: "" });
    case "GET_SIA_EXPIRING_GUARDS_SUCCESS":
      return updateObject(state, {
        siaExpiringGuards: action.response,
        error: "",
        fetchingSIAExpiring: false,
      });

    case "GET_SIA_EXPIRING_GUARDS_FAILED":
      return updateObject(state, {
        error: getError(action.response),
        fetchingSIAExpiring: false,
      });
    case "GET_BRP_EXPIRED_GUARDS":
      return updateObject(state, { fetchingBRPExpired: true, error: "" });
    case "GET_BRP_EXPIRED_GUARDS_SUCCESS":
      return updateObject(state, {
        brpExpiredGuards: action.response,
        error: "",
        fetchingBRPExpired: false,
      });

    case "GET_BRP_EXPIRED_GUARDS_FAILED":
      return updateObject(state, {
        error: getError(action.response),
        fetchingBRPExpired: false,
      });

    case "GET_BRP_EXPIRING_GUARDS":
      return updateObject(state, { fetchingBRPExpiring: true, error: "" });
    case "GET_BRP_EXPIRING_GUARDS_SUCCESS":
      return updateObject(state, {
        brpExpiringGuards: action.response,
        error: "",
        fetchingBRPExpiring: false,
      });

    case "GET_BRP_EXPIRING_GUARDS_FAILED":
      return updateObject(state, {
        error: getError(action.response),
        fetchingBRPExpiring: false,
      });
    case "DELETE_DOC_SUCCESS":
      return updateObject(state, {
        isGuardUpToDate: false,
        error: "",
      });

    default:
      return state;
  }
}
