import Service from "api/service";

export const getAllClients = () => dispatch => {
  Service.get(dispatch, "clients/", {
    start: "GET_ALL_CLIENTS",
    success: "GET_ALL_CLIENTS_SUCCESS",
    failure: "GET_ALL_CLIENTS_FAILED",
  });
};

export const createClient = (body, contentType) => dispatch => {
  Service.post(
    dispatch,
    "clients/",
    body,
    {
      start: "CREATE_CLIENT",
      success: "CREATE_CLIENT_SUCCESS",
      failure: "CREATE_CLIENT_FAILED",
    },
    contentType
  );
};

export const updateClient = (id, body, contentType) => dispatch => {
  Service.patch(
    dispatch,
    `clients/${id}/`,
    body,
    {
      start: "UPDATE_CLIENT",
      success: "UPDATE_CLIENT_SUCCESS",
      failure: "UPDATE_CLIENT_FAILED",
    },
    contentType
  );
};

export const deleteClient = id => dispatch => {
  Service.delete(dispatch, `clients/${id}/`, {
    start: "DELETE_CLIENT",
    success: "DELETE_CLIENT_SUCCESS",
    failure: "DELETE_CLIENT_FAILED",
  });
};

export const changeClient = id => dispatch => {
  Service.setClient(dispatch, id, {
    start: "SET_CLIENT",
    success: "SET_CLIENT_SUCCESS",
    failure: "SET_CLIENT_FAILED",
  });
};
