import Service from "api/service";

export const getAllMessages =
  (queryParams, action = "GET_MESSAGES") =>
  (dispatch) => {
    Service.get(
      dispatch,
      "chat/messages/",
      {
        start: action,
        success: action + "_SUCCESS",
        failure: action + "_FAILED",
      },
      queryParams
    );
  };

export const postMessage = (body, contentType) => (dispatch) => {
  Service.post(
    dispatch,
    "chat/messages/",
    body,
    {
      start: "POST_MESSAGE",
      success: "POST_MESSAGE_SUCCESS",
      failure: "POST_MESSAGE_FAILED",
    },
    contentType
  );
};

export const AddLocalMessage = (body) => (dispatch) => {
  dispatch({ type: "ADD_LOCAL_MESSAGE", response: body });
};
