import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";

const ShiftStaffGrid = ({ shiftReducer, currentDate, mode, filterText }) => {
  const [filteredShifts, setFilteredShifts] = useState(shiftReducer.shiftList);
  let total_hrs = 0;
  const dayArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    if (filterText !== "") {
      setFilteredShifts(
        shiftReducer.shiftList?.filter((s) =>
          s.full_name.toLowerCase().includes(filterText.toLowerCase())
        )
      );
    } else {
      setFilteredShifts(shiftReducer.shiftList);
    }
  }, [filterText, shiftReducer.shiftList]);

  const getCurrentWeekDates = () => {
    const dateArray = [];
    let loopTill = 0;
    let dayOfWeek = currentDate.day();
    if (mode === 30) {
      dayOfWeek = currentDate.startOf("month").day() + 2;
      loopTill = currentDate.endOf("month").date();
    } else {
      loopTill = 7;
    }

    if (!dayOfWeek) dayOfWeek = 6;
    else dayOfWeek = dayOfWeek - 1;

    const monday = currentDate.subtract(dayOfWeek, "day");
    for (let i = 0; i < loopTill; i++) {
      dateArray.push(monday.add(i, "day").format("YYYY-MM-DD"));
    }
    return dateArray;
  };

  shiftReducer.shiftList.map((data, i) =>
    data?.shifts[0]?.total_hours
      ? (total_hrs = total_hrs + 1)
      : (total_hrs = total_hrs + 0)
  );

  return (
    <div>
      <table className="table table-striped table-bordered">
        <thead className="">
          <tr>
            <th style={{ minWidth: "150px" }}>Employee Name</th>
            {getCurrentWeekDates().map((dateValue, i) => (
              <th style={{ minWidth: "150px" }} key={i}>
                <div className="">
                  <span>{dayArray[dayjs(dateValue).day()]}</span>
                  <br />
                  <span>{dayjs(dateValue).format("DD-MM-YYYY")}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredShifts.map(
            (data, i) =>
              data?.shifts.length > 0 && (
                <tr key={i}>
                  <td>{data.full_name}</td>
                  {getCurrentWeekDates().map((dateValue, i) => (
                    <td key={i}>
                      {data?.shifts?.map(
                        (details) =>
                          dateValue.includes(details.start_date) && (
                            <div
                              className={`pointer ${
                                details.status === "unassigned"
                                  ? dayjs(dateValue).date() < dayjs().date()
                                    ? "calendarDiv blur"
                                    : "calendarDiv"
                                  : details.status === "assigned"
                                  ? dayjs(dateValue).date() < dayjs().date()
                                    ? "allocatedDiv blur"
                                    : "allocatedDiv"
                                  : details.status === "cancelled"
                                  ? dayjs(dateValue).date() < dayjs().date()
                                    ? "cancelDiv blur"
                                    : "cancelDiv"
                                  : dayjs(dateValue).date() < dayjs().date()
                                  ? "confirmedDiv blur"
                                  : "confirmedDiv"
                              }`}
                              id={details.id}
                              key={details.id}
                              title={dateValue}
                            >
                              <p>
                                {details.scheduled_start_time +
                                  "-" +
                                  details.scheduled_finish_time}
                              </p>
                              <p>{details.site_name}</p>
                            </div>
                          )
                      )}
                    </td>
                  ))}
                </tr>
              )
          )}
        </tbody>
      </table>
      {(total_hrs === 0 || filteredShifts?.length === 0) && (
        <div className="text-center">No Shifts To Display</div>
      )}
    </div>
  );
};

export default connect(
  ({ shiftReducer }) => ({ shiftReducer }),
  {}
)(ShiftStaffGrid);
