import { MessageList, Input } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import "./chat.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  getAllMessages,
  postMessage,
  AddLocalMessage,
} from "store/action/chatAction";
const Communication = () => {
  const [message, setMessage] = useState("");
  const addMessage = () => {
    if (message) {
      dispatch(
        AddLocalMessage({
          message,
          position: "right",
          user: window.sessionStorage.getItem("username"),
          date: new Date(),
        })
      );
      dispatch(postMessage({ message }));
      setMessage("");
    }
  };

  const data = useSelector((state) => state.chatReducer);
  const dispatch = useDispatch();

  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!data.isMessagesUpToDate) dispatch(getAllMessages());
  }, [dispatch, data.isMessagesUpToDate]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [data.isMessagesUpToDate]);

  const dataSource = useMemo(() => {
    return data.messages.map((m) => ({
      ...m,
      title: m.user,
      type: "text",
      text: m.message,
      position:
        window.sessionStorage.getItem("username") === m.user ? "right" : "left",
      date: m.created_at,
    }));
  }, [data.messages]);

  return (
    <div className="chat-container" ref={messagesEndRef}>
      <button
        className="btn btn-primary refresh-btn fa fa-refresh"
        type="button"
        onClick={() => dispatch(getAllMessages())}
      >
        Refresh
      </button>
      <div className="message-list">
        <MessageList
          className="message-list"
          lockable={true}
          toBottomHeight={"100%"}
          dataSource={dataSource}
        />
      </div>

      <div className="message-input">
        <Input
          placeholder="Type here..."
          onKeyPress={(e) => {
            if (e.shiftKey && e.key === "Enter") {
              return true;
            }
            if (e.key === "Enter") {
              addMessage(e.target.value);
            }
          }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rightButtons={
            <button
              text="Submit"
              className="btn btn-primary"
              onClick={() => addMessage()}
            >
              Send
            </button>
          }
        />
      </div>
    </div>
  );
};

export default Communication;
