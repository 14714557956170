import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getPayrollReports } from "store/action/reports";
import { getAllSites } from "store/action/siteAction";
import { resetCloseModal } from "store/action/alerts";
import ReportGrid from "components/Report";
import ExportJsonExcel from "js-export-excel";
import dayjs from "dayjs";
import { getAllGuards } from "store/action/guardAction";
const Payroll = ({
  getPayrollReports,
  reportReducer,
  resetCloseModal,
  clientReducer,
  getAllSites,
  siteReducer,
  guardReducer,
  getAllGuards,
}) => {
  const dayArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let [dateFrom, setDateFrom] = useState(null);
  let [dateTo, setDateTo] = useState(null);
  let company = 0;
  const [changeDate, setChangeDate] = useState(false);

  const [employee, setEmployee] = useState(false);
  useEffect(() => {
    if (!siteReducer.isSiteUpToDate) getAllSites();
    getAllGuards();
  }, [getAllSites, siteReducer.isSiteUpToDate]);

  useEffect(() => {
    resetCloseModal();
  }, [resetCloseModal]);

  const filterReports = () => {
    let params = {};

    if ((dateFrom && dateTo) || company) {
      params = {
        from_dt: dateFrom,
        to_dt: dateTo,
        client: clientReducer.isClientUpToDate && clientReducer.clientId,
      };
      if (company > 0) params["site"] = company;
      if (parseInt(employee)) params["guard"] = employee;
      getPayrollReports(params);
      setChangeDate(true);
    }
  };

  const exportAll = () => {
    let data = reportReducer.payrollList.data;
    let option = {};
    let dataTable = [];
    let empTotal = [];
    if (data.length > 0) {
      if (data) {
        dataTable.push([
          `PAYROLL FOR THE PERIOD:${
            changeDate
              ? ` ${dayjs(reportReducer?.payrollList?.from_date).format(
                  "DD-MM-YYYY"
                )} To ${dayjs(reportReducer?.payrollList?.to_date).format(
                  "DD-MM-YYYY"
                )}`
              : " For Current Week"
          }`,
        ]);
        data.forEach((object) => {
          let detailsArray = [];
          let obj = [
            "Employee Name",
            object.full_name,
            "Sort Code",
            object.sort_code,
            "Account Number",
            object.account_number,
          ];

          object.details.forEach((details) => {
            let detailsObj = {
              "Site Name": details.site,
              "Day ": dayArray[dayjs(details.start_date).day()],
              "Start Date":
                dayjs(details.start_date).format("DD-MM-YYYY") +
                details.actual_start_time,
              "Finish Date":
                dayjs(details.start_date).format("DD-MM-YYYY") +
                details.actual_finish_time,
              "Hourly Rate": object.houry_rate,
              "Hours Worked": details.actual_total_hours,
              "Shift Allowance ": details.shift_allowance,
              "Pay ": details.pay,
              "Comments ": details.comment,
            };
            detailsArray.push(detailsObj);
          });
          empTotal = [
            " ",
            " ",
            " ",
            " ",
            "Employee Totals",
            object.total_hours,
            object.total_allowance,
            object.total_pay,
          ];

          dataTable.push({});
          dataTable.push(obj);
          dataTable.push({});
          dataTable.push({
            "Site Name": "Site Name",
            "Day ": "Day ",
            "Start Date": "Start Date",
            "Finish Date": "Finish Date",
            "Hourly Rate": "Hourly Rate (£)",
            "Hours Worked": "Hours Worked (Hrs)",
            "Shift Allowance": "Shift Allowance (£)",
            "Pay ": "Pay (£)",
            "Comments ": "Comments ",
          });

          detailsArray.map((detailObj) => dataTable.push(detailObj));
          dataTable.push({});
          dataTable.push(empTotal);
          dataTable.push({});
        });
      }
    }
    option.fileName = `Payroll-Details-${new Date()}`;
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: "sheet",
      },
    ];

    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };
  useEffect(() => {
    const params = {};
    if (clientReducer.clientId) {
      params["client"] = clientReducer.clientId;
    }
    if (!reportReducer.isReportUpToDate) getPayrollReports(params);
  }, [reportReducer.isReportUpToDate, getPayrollReports]);

  useEffect(() => {
    if (!clientReducer.isClientUpToDate)
      getPayrollReports({ client: clientReducer.clientId });
  }, [
    getPayrollReports,
    clientReducer.isClientUpToDate,
    clientReducer.clientId,
  ]);

  return (
    <div className="col-12 border">
      <div className="d-flex justify-content-between card-header">
        <div>
          <h3>Payroll Section</h3>
          <h6>
            By default this report fetches the payroll for current week. If you
            want payroll for other dates/clients you can use filters provided
            below
          </h6>
        </div>
        <div>
          <Link
            className="btn btn-primary  mt-3"
            to={{
              pathname: "/guest-payroll",
            }}
          >
            <i className="fa fa-user-plus"></i>
            View Only Guest
          </Link>
        </div>
      </div>

      <div className="input-group mt-4 d-flex ">
        <div className="ms-2">
          <label htmlFor="dob" className="font-weight-bold">
            <b> Date From :</b>
          </label>
          <input
            type="date"
            className="form-control"
            id="date_from"
            placeholder="Date From"
            onChange={(e) => setDateFrom(e.target.value)}
            name="date_from"
          />
        </div>
        <div className="ms-2">
          <label htmlFor="dob" className="font-weight-bold">
            <b>Date To :</b>
          </label>
          <input
            type="date"
            className="form-control"
            id="date_to"
            placeholder="Date To"
            onChange={(e) => setDateTo(e.target.value)}
            name="date_to"
          />
        </div>
        <div className="form-group ms-2  ">
          <label htmlFor="name" className="font-weight-bold">
            Company/Site name<span className="req">*</span> :
          </label>
          <select
            type="text"
            className="form-control"
            id="company"
            onChange={(e) => (company = e.target.value)}
            placeholder="Company/Site"
            name="company"
            required
          >
            <option value="0">--Select From The List--</option>
            {siteReducer.siteList &&
              siteReducer.siteList.map((obj) => {
                return (
                  <option value={obj.id} key={obj.id} label={obj.site_name}>
                    {obj.site_name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="form-group ms-2  ">
          <label htmlFor="emp" className="font-weight-bold">
            Employee :
          </label>
          <select
            type="text"
            className="form-control"
            id="emp"
            onChange={(e) => setEmployee(e.target.value)}
            placeholder="employee"
            name="emp"
            required
          >
            <option value="0">--Select From The List--</option>
            {guardReducer.guardList &&
              guardReducer.guardList?.map((obj) => {
                return (
                  <option value={obj.id} key={obj.id} label={obj.full_name}>
                    {obj.full_name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="mt-4 ms-2">
          <button
            className="btn btn-primary text-white mt-1"
            onClick={() => {
              filterReports();
            }}
          >
            {reportReducer.loading ? (
              <>
                <i className="fa fa-spinner fa-spin" />
                <span> Processing...</span>
              </>
            ) : (
              <span>Generate Report</span>
            )}
          </button>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center card-header mt-2">
        <h6>
          Total Pay{" "}
          {changeDate
            ? ` For period ${dayjs(
                reportReducer?.payrollList?.from_date
              ).format("DD-MM-YYYY")} To ${dayjs(
                reportReducer?.payrollList?.to_date
              ).format("DD-MM-YYYY")}`
            : " For Current Week"}{" "}
          For {reportReducer?.payrollList?.hrs} hrs Is{" "}
          <i className="fa fa-gbp"></i>
          {reportReducer?.payrollList?.pay}. The Payroll Has{" "}
          {reportReducer?.payrollList?.staff_count} Staff Members
        </h6>
      </div>
      <div className="mt-4">
        <ReportGrid reportReducer={reportReducer} exportAll={exportAll} />
      </div>
    </div>
  );
};
export default connect(
  ({ reportReducer, clientReducer, siteReducer, guardReducer }) => ({
    reportReducer,
    clientReducer,
    guardReducer,
    siteReducer,
  }),
  {
    resetCloseModal,
    getPayrollReports,
    getAllSites,
    getAllGuards,
  }
)(Payroll);
