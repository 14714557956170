import React, { useState } from "react";
import Footer from "components/Footer";
import Sidebar from "components/Navbar";
import styles from "./Layout.module.scss";

const Layout = ({ children }) => {
  const [sidebarFlag, setSideBarFlag] = useState(true);
  return (
    <div className={styles.pageContainer}>
      <Sidebar sidebarFlag={sidebarFlag} setSideBarFlag={setSideBarFlag} />
      <div
        className={`${styles.mainContent} ${
          sidebarFlag && styles.expandedMainContent
        }`}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
