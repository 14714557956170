import { updateObject } from "utils/utility";
import { getError } from "utils/utility";

const initialState = {
  isRetrieving: false,
  messages: [],
  isMessagesUpToDate: false,
  loading: false,
  error: "",
};

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_MESSAGES":
      return updateObject(state, {
        loading: true,
        isRetrieving: true,
        error: "",
        messages: [],
      });
    case "GET_MESSAGES_SUCCESS":
      return updateObject(state, {
        isMessagesUpToDate: true,
        isRetrieving: false,
        loading: false,
        messages: action.response,
        error: "",
      });
    case "GET_MESSAGES_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
        isMessagesUpToDate: false,
        error: getError(action.response),
      });

    case "ADD_LOCAL_MESSAGE":
      const newMessages = state.messages.concat(action.response);
      return updateObject(state, {
        messages: newMessages,
      });

    case "POST_MESSAGE_SUCCESS":
      return updateObject(state, {
        isMessagesUpToDate: false,
      });

    default:
      return state;
  }
}
