import Alert from "components/Alert";
import Modal from "components/Modal";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  getBookonShifts,
  updateShift,
  resetBookonData,
} from "store/action/shiftAction";
import HourlyCheckBoxes from "./Checkbox";

const Bookon = ({
  getBookonShifts,
  updateShift,
  resetBookonData,
  shiftReducer,
}) => {
  const [activeShift, setActiveShift] = useState(false);
  let [dateFrom, setDateFrom] = useState(null);
  let [dateTo, setDateTo] = useState(null);
  const [body, setBody] = useState({ bookon: "manual" });
  let close = useRef(null);
  const [filterText, setFilterText] = useState("");
  const [filteredBookonShifts, setFilteredBookonShifts] = useState(
    shiftReducer.bookonShiftList
  );

  const fetchBookonShifts = () => {
    const params = {
      bookon: true,
    };
    if (dateFrom && dateTo) {
      params["from_dt"] = dateFrom;
      params["to_dt"] = dateTo;
    }
    getBookonShifts(params);
  };

  useEffect(() => {
    if (!shiftReducer.isBookonShiftListUpToList) fetchBookonShifts();
  }, [shiftReducer.isBookonShiftListUpToList]);

  useEffect(() => {
    return () => {
      resetBookonData();
    };
  }, []);

  useEffect(() => {
    if (shiftReducer.isShiftUpToDate) {
      closeModal();
    }
  }, [shiftReducer.isShiftUpToDate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let subBody = { ...body };
    subBody[name] = value;
    setBody(subBody);
  };

  const checkManualBookonTime = (givenTime, start_date) => {
    if (
      new Date(start_date).setHours(0, 0, 0, 0) !==
      new Date().setHours(0, 0, 0, 0)
    ) {
      return true;
    }
    const [givenHours, givenMinutes] = givenTime.split(":").map(Number);
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const totalGivenMinutes = givenHours * 60 + givenMinutes;
    const totalCurrentMinutes = currentHours * 60 + currentMinutes;
    return !(
      totalCurrentMinutes - totalGivenMinutes <= 10 &&
      totalCurrentMinutes - totalGivenMinutes > -10
    );
  };

  const updateShiftDetails = (bookonType, shift) => {
    if (bookonType === "self") {
      if (
        !checkManualBookonTime(shift.scheduled_start_time, shift.start_date)
      ) {
        updateShift(shift.id, { bookon: bookonType });
      } else {
        alert(
          "Uh oh! The start time exceeded the current time with 10 mins, Please proceed with manual bookon."
        );
      }
    } else {
      if (
        !!checkManualBookonTime(shift.scheduled_start_time, shift.start_date)
      ) {
        setActiveShift(shift);
        document.querySelector("#manual-bookon").click();
        setBody({ bookon: "manual" });
      } else {
        alert(
          "Uh oh! The start time must exceed the current time with 10 mins in order to do this."
        );
      }
    }
  };

  const closeModal = () => {
    if (document.getElementById("manualBookonForm")) {
      document.getElementById("manualBookonForm").reset();
    }
    document.querySelector(".btn-close").click();
  };

  const manualBookon = () => {
    updateShift(activeShift.id, body);
  };

  useEffect(() => {
    if (filterText !== "") {
      setFilteredBookonShifts(
        shiftReducer.bookonShiftList?.filter((s) =>
          s.site_name.toLowerCase().includes(filterText.toLowerCase())
        )
      );
    } else {
      setFilteredBookonShifts(shiftReducer.bookonShiftList);
    }
  }, [filterText, shiftReducer.bookonShiftList]);

  return (
    <div className="col-12">
      <Alert />
      <div className="input-group mt-4 d-flex justify-content-center align-items-center">
        <div className="ms-2">
          <label htmlFor="dob" className="font-weight-bold">
            <b> Date From :</b>
          </label>
          <input
            type="date"
            className="form-control"
            id="date_from"
            placeholder="Date From"
            onChange={(e) => setDateFrom(e.target.value)}
            name="date_from"
          />
        </div>
        <div className="ms-2">
          <label htmlFor="dob" className="font-weight-bold">
            <b>Date To :</b>
          </label>
          <input
            type="date"
            className="form-control"
            id="date_to"
            placeholder="Date To"
            onChange={(e) => setDateTo(e.target.value)}
            name="date_to"
          />
        </div>
        <div className="mt-4 ms-2">
          <button
            className={`btn text-white mt-1 btn-primary`}
            onClick={() => {
              fetchBookonShifts();
            }}
          >
            {shiftReducer.loading ? (
              <>
                <i className="fa fa-spinner fa-spin" />
                <span> Downloading...</span>
              </>
            ) : (
              <span>{"Submit"}</span>
            )}
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-evenly w-100 m-2">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="site name to search..."
            type="text"
            onChange={(e) => {
              setFilterText(e.target.value);
            }}
          />
        </div>
        <strong>
          Displaying confirmed shifts{" "}
          {dateFrom && dateTo
            ? `from ${dateFrom} to ${dateTo}`
            : "of yesterday and today"}
        </strong>
        <button
          className="btn btn-primary mb-2"
          onClick={(e) => {
            fetchBookonShifts();
          }}
        >
          Refresh shifts
        </button>
      </div>
      <table className="table table-striped table-bordered">
        <thead className="">
          <tr>
            <th>Site Name</th>
            <th>Officer Name</th>
            <th>Shift Date</th>
            <th>Shift Times</th>
            <th>Actual Times</th>
            <th>Check calls</th>
            <th>Bookon</th>
            <th>Bookon By</th>
            <th>Bookon Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredBookonShifts?.length ? (
            filteredBookonShifts.map((shift) => {
              return (
                <tr>
                  <td>
                    <div className="d-flex flex-column">
                      <span>
                        <strong>{shift.site_name}</strong>
                      </span>
                      <span>Postal code {shift.postal_code}</span>
                      <span>Total hours {shift.total_hours}</span>
                      <span>Actual hours {shift.actual_total_hours}</span>
                    </div>
                  </td>
                  <td>
                    {shift.guard} ({shift.guard_pin || "-"})
                  </td>
                  <td>{shift.start_date}</td>
                  <td>
                    {shift.scheduled_start_time} - {shift.scheduled_finish_time}
                  </td>
                  <td>
                    {shift.actual_start_time} - {shift.actual_finish_time}
                  </td>
                  <td>
                    {shift.check_calls_needed ? (
                      <div>
                        <HourlyCheckBoxes
                          startTime={shift.scheduled_start_time}
                          endTime={shift.scheduled_finish_time}
                          shift={shift.id}
                          check_calls={shift.check_calls}
                          start_date={shift.start_date}
                        />
                      </div>
                    ) : (
                      <p>Not enabled</p>
                    )}
                  </td>
                  <td>{shift.bookon}</td>
                  <td>{shift.bookon_by}</td>
                  <td>
                    <div className="d-flex">
                      <button
                        className="btn btn-primary ms-3 "
                        onClick={() => {
                          updateShiftDetails("self", shift);
                        }}
                        disabled={checkManualBookonTime(
                          shift.scheduled_start_time,
                          shift.start_date
                        )}
                      >
                        {shiftReducer.loading ? (
                          <>
                            <i className="fa fa-spinner fa-spin" />
                            <span> Updating...</span>
                          </>
                        ) : (
                          <span>Self</span>
                        )}
                      </button>
                      <button
                        className="btn btn-warning ms-3"
                        onClick={() => {
                          updateShiftDetails("manual", shift, shift);
                        }}
                        disabled={
                          !checkManualBookonTime(
                            shift.scheduled_start_time,
                            shift.start_date
                          )
                        }
                      >
                        Manual
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <p>No records to display</p>
          )}
        </tbody>
      </table>
      <button
        className="btn btn-primary d-none"
        id="manual-bookon"
        data-bs-toggle="modal"
        data-bs-target="#commonModal"
      ></button>
      <Modal title="Manual Book on">
        <form
          id="manualBookonForm"
          className="p-2"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="form-group mt-2  ">
            <label className="font-weight-bold">Actual Start Time:</label>
            <input
              type="time"
              className="form-control"
              id="actual_start_time"
              name="actual_start_time"
              defaultValue={activeShift.actual_start_time}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group mt-2  ">
            <label className="font-weight-bold">Actual Finish Time:</label>
            <input
              type="time"
              className="form-control"
              id="actual_finish_time"
              name="actual_finish_time"
              defaultValue={activeShift.actual_finish_time}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group mt-2  ">
            <label className="font-weight-bold">comments:</label>
            <textarea
              className="form-control"
              id="comment"
              name="comment"
              onChange={handleChange}
              defaultValue={activeShift.comment}
              required
            />
          </div>
          <div className="d-flex justify-content-center mt-2">
            <button
              className="btn btn-warning"
              onClick={() => manualBookon()}
              disabled={!(body?.actual_start_time && body?.comment)}
            >
              {shiftReducer.loading ? (
                <>
                  <i className="fa fa-spinner fa-spin" />
                  <span> Updating...</span>
                </>
              ) : (
                <span>Manual Bookon</span>
              )}
            </button>
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={() => closeModal()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-warning ms-2"
              data-bs-dismiss="modal"
              ref={close}
              onClick={() => closeModal()}
              hidden={true}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};
export default connect(({ shiftReducer }) => ({ shiftReducer }), {
  getBookonShifts,
  updateShift,
  resetBookonData,
})(Bookon);
