import dayjs from "dayjs";
import React from "react";

const DetailedPayroll = ({ data, isInvoice }) => {
  const dayArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return (
    <div>
      <table className="table table-striped table-bordered">
        <thead className="">
          <tr>
            <td>
              <h4>{isInvoice ? "Site Name" : data.full_name}</h4>
            </td>
            <td>
              <h4>Day</h4>
            </td>
            <td>
              <h4>Start Date</h4>
            </td>
            <td>
              <h4>Time</h4>
            </td>
            <td>
              <h4>Hours Worked</h4>
            </td>
            <td>
              <h4>Rate</h4>
            </td>
            {!isInvoice && (
              <td>
                <h4>Allowance</h4>
              </td>
            )}
            <td>
              <h4>Pay</h4>
            </td>
          </tr>
        </thead>
        <tbody>
          {data?.details?.map(empDetailObj => {
            return (
              <tr>
                <td>{empDetailObj.site || data.site_name}</td>
                <td>
                  <span>{dayArray[dayjs(empDetailObj.start_date).day()]}</span>
                </td>
                <td>
                  <span>{empDetailObj.start_date}</span>
                </td>
                <td>
                  {isInvoice
                    ? empDetailObj.scheduled_start_time +
                      "-" +
                      empDetailObj.scheduled_finish_time
                    : empDetailObj.actual_start_time +
                      "-" +
                      empDetailObj.actual_finish_time}
                </td>
                <td>
                  {isInvoice
                    ? empDetailObj.total_hours
                    : empDetailObj.actual_total_hours}{" "}
                  hrs
                </td>
                <td>
                  <i className="fa fa-gbp"></i>
                  {isInvoice ? data?.charge_rate : data?.houry_rate}
                </td>
                {!isInvoice && (
                  <td>
                    <i className="fa fa-gbp"></i>
                    {empDetailObj.shift_allowance}
                  </td>
                )}
                <td>
                  <i className="fa fa-gbp"></i>
                  {empDetailObj.pay}
                </td>
              </tr>
            );
          })}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>Total: {data.total_hours} hrs</td>
            <td></td>
            {!isInvoice && (
              <td>
                Total :<i className="fa fa-gbp"></i>
                {data.total_allowance}
              </td>
            )}
            <td>
              Total :<i className="fa fa-gbp"></i>
              {data.total_pay}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default DetailedPayroll;
