import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "components/Modal";
import Grid from "components/DataTables";
import SiteForm from "./siteForm";
import { getAllSites, deleteSite } from "store/action/siteAction";
import Alert from "components/Alert";

const Site = ({ getAllSites, siteReducer, clientReducer, deleteSite }) => {
  const [data, setData] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (!siteReducer.isSiteUpToDate)
      getAllSites({ client: clientReducer.clientId });
  }, [getAllSites, siteReducer.isSiteUpToDate]);

  useEffect(() => {
    if (!clientReducer.isClientUpToDate)
      getAllSites({ client: clientReducer.clientId });
  }, [getAllSites, clientReducer.isClientUpToDate, clientReducer.clientId]);

  const ActionRenderer = ({ data }) => {
    return (
      <>
        <button
          data-bs-toggle="modal"
          data-bs-target="#commonModal"
          className="btn fa fa-pencil-square ms-2"
          onClick={() => {
            setData(data);
            setIsUpdating(true);
          }}
        />
        <button
          className="btn fa fa-trash ms-2"
          onClick={() => deleteSite(data.id)}
        />
      </>
    );
  };

  const siteColumns = {
    columnDefs: [
      { headerName: "#", field: "id", sortable: true },
      {
        headerName: "Site Name",
        field: "site_name",
        sortable: true,
        flex: 1,
      },

      { headerName: "Address", field: "address", flex: 1 },
      {
        headerName: "Phone #",
        field: "phone",
        sortable: true,
      },
      {
        headerName: "Postal Code",
        field: "postal_code",
        sortable: true,
        flex: 1,
      },
      {
        headerName: "Actions",
        cellRenderer: ActionRenderer,
        width: 150,
        pinned: "right",
      },
    ],
  };

  return (
    <>
      <Modal title={isUpdating ? "Update Site" : "New Site"}>
        <SiteForm data={data} isUpdating={isUpdating} />
      </Modal>
      <div className="col-xl-12 col-12 border">
        <Alert />
        <div className=" card-header">
          <h5>Note: Please select client before creating a site</h5>
        </div>
        <div className="card mt-3">
          <div className="card-header">
            <h4 className="text-center">Sites</h4>
          </div>
          <div className="card-body">
            <Grid
              columnDefs={siteColumns}
              rowData={siteReducer.siteList}
              dataRetrieved={!siteReducer.isRetrieving}
            >
              <button
                className="btn btn-primary fa fa-plus text-white"
                data-bs-toggle="modal"
                data-bs-target="#commonModal"
                onClick={() => {
                  setData({});
                  setIsUpdating(false);
                }}
              >
                <span className="ms-2 text-white"> Add Site </span>
              </button>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(
  ({ siteReducer, clientReducer }) => ({ siteReducer, clientReducer }),
  {
    getAllSites,
    deleteSite,
  }
)(Site);
