import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllUsers, deleteUser } from "store/action/user";
import Grid from "components/DataTables";
import UserForm from "./userForm";
import Modal from "components/Modal";
import Alert from "components/Alert";
const User = ({ getAllUsers, userReducer, deleteUser }) => {
  const [data, setData] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (!userReducer.isUsersListUpToDate) {
      getAllUsers();
    }
  }, [userReducer.isUsersListUpToDate, getAllUsers]);

  const ActionRenderer = ({ data }) => {
    return (
      <>
        <button
          data-bs-toggle="modal"
          data-bs-target="#commonModal"
          className="btn fa fa-pencil-square ms-2"
          onClick={() => {
            setData(data);
            setIsUpdating(true);
          }}
        />
        <button
          className="btn fa fa-trash ms-2"
          onClick={() => deleteUser(data.id)}
        />
      </>
    );
  };

  const userColumns = {
    columnDefs: [
      { headerName: "#", field: "id", sortable: true },
      {
        headerName: "Email",
        field: "email",
        sortable: true,
      },
      {
        headerName: "Last LoggedIn",
        field: "last_login",
        flex: 1,
      },
      {
        headerName: "Actions",
        cellRenderer: ActionRenderer,
      },
    ],
  };

  return (
    <>
      <Modal title="New User">
        <UserForm data={data} isUpdating={isUpdating} />
      </Modal>

      <div className="col-xl-2"></div>
      <div className="col-xl-8 col-12 ">
        <Alert />
        <div className="card ">
          <div className="card-header">
            <h4 className="text-center">Users</h4>
          </div>
          <div className="card-body">
            <Grid
              columnDefs={userColumns}
              rowData={userReducer.usersList}
              dataRetrieved={!userReducer.isRetrieving}
            >
              <button
                className="btn btn-primary fa fa-plus text-white"
                data-bs-toggle="modal"
                data-bs-target="#commonModal"
                onClick={() => {
                  setData({});
                  setIsUpdating(false);
                }}
              >
                <span className="ms-2 text-white"> Add Users </span>
              </button>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(({ userReducer }) => ({ userReducer }), {
  getAllUsers,
  deleteUser,
})(User);
