import React from "react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./store/reducer";
import { thunk } from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
//jQuery & bootstrap
import "popper.js/dist/umd/popper.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "./static/fontawesome/styles.min.css";
import { createRoot } from "react-dom/client";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
