import Service from "api/service";

export const getPayrollReports =
  (queryParams, action = "GET_PAYROLL") =>
  (dispatch) => {
    Service.get(
      dispatch,
      "report/payroll/",
      {
        start: action,
        success: action + "_SUCCESS",
        failure: action + "_FAILED",
      },
      queryParams
    );
  };

export const getInvoiceReports =
  (queryParams, action = "GET_INVOICE") =>
  (dispatch) => {
    Service.get(
      dispatch,
      "report/invoice/",
      {
        start: action,
        success: action + "_SUCCESS",
        failure: action + "_FAILED",
      },
      queryParams
    );
  };

export const getRota =
  (queryParams, action = "GET_ROTA") =>
  (dispatch) => {
    Service.get(
      dispatch,
      "report/rota/",
      {
        start: action,
        success: action + "_SUCCESS",
        failure: action + "_FAILED",
      },
      queryParams
    );
  };

export const getBookonReport = (queryParams) => (dispatch) => {
  Service.get(
    dispatch,
    "report/bookon/",
    {
      start: "GET_BOOKON_REPORT",
      success: "GET_BOOKON_REPORT_SUCCESS",
      failure: "GET_BOOKON_REPORT_FAILED",
    },
    queryParams
  );
};
