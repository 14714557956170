import { updateObject } from "utils/utility";
import { getError } from "utils/utility";

const initialState = {
  isClientUpToDate: false,
  closeModal: false,
  loading: false,
  isRetrieving: false,
  clientList: [],
  error: "",
  clientId: 0,
};

export default function clientReducer(state = initialState, action) {
  switch (action.type) {
    case "CONNECTION_REFUSED":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
      });
    case "GET_ALL_CLIENTS":
      return updateObject(state, {
        isRetrieving: true,
        error: "",
      });
    case "GET_ALL_CLIENTS_SUCCESS":
      return updateObject(state, {
        isClientUpToDate: true,
        isRetrieving: false,
        clientList: action.response,
        error: "",
      });
    case "GET_ALL_CLIENTS_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        isClientUpToDate: false,
        error: getError(action.response),
      });
    case "CREATE_CLIENT":
      return updateObject(state, {
        loading: true,
        isRetrieving: true,
        error: "",
      });
    case "CREATE_CLIENT_SUCCESS":
      return updateObject(state, {
        isClientUpToDate: false,
        loading: false,
        isRetrieving: false,
        closeModal: true,
        error: "",
      });
    case "CREATE_CLIENT_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
        error: getError(action.response),
      });
    case "UPDATE_CLIENT":
      return updateObject(state, {
        loading: true,
        error: "",
      });
    case "UPDATE_CLIENT_SUCCESS":
      return updateObject(state, {
        isClientUpToDate: false,
        closeModal: true,
        loading: false,
        error: "",
      });
    case "UPDATE_CLIENT_FAILED":
      return updateObject(state, {
        loading: false,
        error: getError(action.response),
      });
    case "DELETE_CLIENT":
      return updateObject(state, { loading: true, error: "" });
    case "DELETE_CLIENT_SUCCESS":
      return updateObject(state, {
        isClientUpToDate: false,
        closeModal: true,
        loading: false,
        error: "",
      });
    case "DELETE_CLIENT_FAILED":
      return updateObject(state, {
        error: getError(action.response),
      });
    case "RESET_CLOSE_MODAL":
      return updateObject(state, {
        closeModal: false,
      });
    case "SET_CLIENT":
      return updateObject(state, {
        clientId: action.payload,
        isClientUpToDate: false,
      });
    default:
      return state;
  }
}
