import React, { useEffect } from "react";
import { connect } from "react-redux";
import { resetAlerts } from "store/action/alerts";

const Alert = ({ alerts, resetAlerts }) => {
  useEffect(() => {
    if (alerts.message || alerts.error){
      window.scrollTo(0, 0); 
      resetAlerts();
    }
  }, [resetAlerts, alerts.error, alerts.message]);
  return alerts.message ? (
    <div className="alert alert-success text-center" role="alert">
      {alerts.message}
    </div>
  ) : alerts.error ? (
    <div className="alert alert-danger  text-center" role="alert">
      {alerts.error[0] === 'Invalid pk "0" - object does not exist.'
        ? "Please select client first"
        : alerts.error[0] ===
          "Ensure this field has no more than 11 characters."
        ? "Mobile number should be only 11 characters"
        : alerts.error}
    </div>
  ) : (
    ""
  );
};

export default connect(({ alerts }) => ({ alerts }), { resetAlerts })(Alert);
