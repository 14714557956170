import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "store/action/user";
import logo from "static/images/tkl_green_logo.jpg";

const Login = ({ login, userReducer }) => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const onsubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem("username", username);
    login({ username: username, password: password });
  };
  if (userReducer.isLoggedIn) {
    return navigate("dashboard");
  } else {
    return (
      <div className="loginImage">
        <div>
          <div className="card logincard text-center ">
            <div className="card-body">
              <img
                src={logo}
                className="card-img-top login-logo"
                alt="Cinque Terre"
                style={{ width: "30%" }}
              />
              <h5 className="ml-0 mt-4 text-center">
                {" "}
                Tklgreen Shift Management
              </h5>
              <h6 className="mb-0 text-center">
                Enter credentials to continue
              </h6>

              <form onSubmit={(e) => onsubmit(e)}>
                <div className="input-group mt-2">
                  <span className="input-group-append">
                    <span
                      className="btn btn-outline-secondary border-right-0 border"
                      type="button"
                    >
                      <i className="fa fa-user"></i>
                    </span>
                  </span>
                  <input
                    type="text"
                    className="form-control border-left-0 border mb-2"
                    placeholder="Username"
                    required
                    autoFocus
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div className="input-group mt-2">
                  <span className="input-group-append">
                    <span
                      className="btn btn-outline-secondary border-right-0 border"
                      type="button"
                    >
                      <i className="fa fa-key"></i>
                    </span>
                  </span>
                  <input
                    type="password"
                    className="form-control border-left-0 border mb-2 "
                    placeholder="Password"
                    id="password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    className="input-group-append"
                    onClick={(e) => {
                      let field = document.querySelector("#password");
                      debugger;
                      field.type = field?.type === "text" ? "password" : "text";
                    }}
                  >
                    <span
                      className="btn btn-outline-secondary border-right-0 border"
                      type="button"
                    >
                      <i className="fa fa-eye-slash" id="togglePassword"></i>
                    </span>
                  </span>
                </div>

                {userReducer.error && (
                  <div className=" text-center text-danger mt-2" role="alert">
                    <h4>{userReducer.error}</h4>
                  </div>
                )}

                <button
                  className="btn form-control btn-primary btn-block mb-1"
                  type="submit"
                >
                  <i className="fa fa-sign-in"></i>
                  {userReducer.loading ? (
                    <>
                      <i className="fa fa-spinner fa-spin" />
                      <span> Processing...</span>
                    </>
                  ) : (
                    <>
                      <span>Sign In </span>
                    </>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default connect(({ userReducer }) => ({ userReducer }), { login })(
  Login
);
