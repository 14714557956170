import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "components/Layout/index";

const ProtectedRoute = ({ userReducer, children }) => {
  const isAuthenticated = userReducer.isLoggedIn;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default connect(
  ({ userReducer }) => ({ userReducer }),
  {}
)(ProtectedRoute);
