import { updateObject } from "utils/utility";
import { getError } from "utils/utility";

const initialState = {
  isSiteUpToDate: false,
  closeModal: false,
  loading: false,
  isRetrieving: false,
  siteList: [],
  error: "",
};

export default function siteReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_SITES":
      return updateObject(state, {
        loading: true,
        isRetrieving: true,
        error: "",
      });
    case "GET_ALL_SITES_SUCCESS":
      return updateObject(state, {
        isSiteUpToDate: true,
        isRetrieving: false,
        loading: false,
        siteList: action.response,
        error: "",
      });
    case "GET_ALL_SITES_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
        error: getError(action.response),
      });
    case "CREATE_SITE":
      return updateObject(state, {
        loading: true,
        isRetrieving: true,
        error: "",
      });
    case "CREATE_SITE_SUCCESS":
      return updateObject(state, {
        isSiteUpToDate: false,
        loading: false,
        isRetrieving: false,
        closeModal: true,
        error: "",
      });
    case "CREATE_SITE_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
        error: getError(action.response),
      });
    case "UPDATE_SITE":
      return updateObject(state, {
        loading: true,
        error: "",
      });
    case "UPDATE_SITE_SUCCESS":
      return updateObject(state, {
        isSiteUpToDate: false,
        closeModal: true,
        loading: false,
        error: "",
      });
    case "UPDATE_SITE_FAILED":
      return updateObject(state, {
        loading: false,
        error: getError(action.response),
      });
    case "DELETE_SITE":
      return updateObject(state, { loading: true, error: "" });
    case "DELETE_SITE_SUCCESS":
      return updateObject(state, {
        isSiteUpToDate: false,
        closeModal: true,
        loading: false,
        error: "",
      });
    case "DELETE_SITE_FAILED":
      return updateObject(state, {
        error: getError(action.response),
      });
    case "RESET_CLOSE_MODAL":
      return updateObject(state, {
        closeModal: false,
      });
    case "CONNECTION_REFUSED":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
      });
    default:
      return state;
  }
}
