import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutAction } from "store/action/user";
import { getAllClients, changeClient } from "store/action/clientAction";
import NurlineLogo from "static/images/tkl_green_logo.jpg";
import { getShiftStats, getBookonStats } from "store/action/shiftAction";

const Sidebar = ({
  logoutAction,
  sidebarFlag,
  setSideBarFlag,
  getShiftStats,
  getBookonStats,
  shiftReducer,
  getAllClients,
  changeClient,
  clientReducer,
}) => {
  const [active, setActive] = useState("");
  const [arrowDown, setArrowDown] = useState(true);
  const toggleFunction = () => {
    setSideBarFlag(!sidebarFlag);
  };

  useEffect(() => {
    const queryParam = {};
    if (!clientReducer.isClientUpToDate) {
      queryParam["client"] = clientReducer.clientId;
    }
    if (!shiftReducer.statsUpToDate) {
      getShiftStats(queryParam);
      getBookonStats();
    }
  }, [
    shiftReducer.stats,
    shiftReducer.statsUpToDate,
    getShiftStats,
    getBookonStats,
    clientReducer.clientId,
    clientReducer.isClientUpToDate,
  ]);

  useEffect(() => {
    if (!clientReducer.isClientUpToDate) getAllClients();
  }, [getAllClients, clientReducer.isClientUpToDate]);

  const setClient = (e) => {
    changeClient(e.target.value);
  };
  const { cancelled, confirmed, total, unassigned, assigned } =
    shiftReducer.stats || {};

  return (
    <>
      {sessionStorage.getItem("login_token") && (
        <div className={sidebarFlag ? "body-pd" : ""} id="body-pd">
          <header
            className={sidebarFlag ? "body-pd header" : "header"}
            id="header"
          >
            <div className="header_toggle">
              <i
                className={sidebarFlag ? " fa fa-close" : "fa fa-bars"}
                id="header-toggle"
                onClick={() => {
                  toggleFunction();
                }}
              ></i>
            </div>
            <div className="w-50">
              <select
                type="text"
                className="form-control"
                id="client"
                placeholder="Client"
                name="client"
                onChange={(e) => setClient(e)}
                required
              >
                <option value="">--Display All Clients--</option>
                {clientReducer.clientList &&
                  clientReducer.clientList.map((obj) => (
                    <option value={obj.id} key={obj.id}>
                      {obj.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="dropdown">
              <button
                className="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-gear"></i>
                Settings
              </button>
              <div
                className="dropdown-menu "
                aria-labelledby="dropdownMenuButton1"
              >
                {JSON.parse(sessionStorage.getItem("user")).role_name ===
                  "superuser" && (
                  <Link
                    key="change-password"
                    className="dropdown-item"
                    to="/change-password"
                  >
                    <i className="fa fa-lock"></i>
                    <span>Change Password</span>
                  </Link>
                )}
                <Link
                  key="sign-out"
                  className="dropdown-item"
                  to="/"
                  onClick={() => logoutAction()}
                >
                  <i className="fa fa-sign-out"></i>
                  <span>Sign-out</span>
                </Link>
              </div>
            </div>
          </header>

          <div
            className={sidebarFlag ? "show-sidebar l-navbar" : "l-navbar"}
            id="nav-bar"
          >
            <nav className="nav">
              <div>
                <div className="nav_logo mt-1 mb-1">
                  <div className="header_img">
                    <img src={NurlineLogo} alt="" />
                  </div>
                  <span className="nav_logo-name"> Shift-Management</span>
                </div>
                <div className="nav_list">
                  <div className="nav_link">
                    {sidebarFlag ? (
                      <span className="text-capitalize">
                        welcome{" "}
                        {sessionStorage.getItem("username")?.split("@")?.[0] ||
                          sessionStorage.getItem("username")}
                        <i className="fa fa-smile-o ms-2"></i>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <Link
                    to="/dashboard"
                    key="dashboard"
                    className={
                      active === "dashboard" ? "nav_link active" : "nav_link"
                    }
                    onClick={() => setActive("dashboard")}
                  >
                    <i className="fa fa-dashboard"></i>
                    {sidebarFlag ? (
                      <span className="nav_name">Dashboard</span>
                    ) : (
                      ""
                    )}
                  </Link>
                  {JSON.parse(sessionStorage.getItem("user")).role_name ===
                    "superuser" && (
                    <Link
                      key="users"
                      to="/users"
                      className={
                        active === "users" ? "nav_link active" : "nav_link"
                      }
                      onClick={() => setActive("users")}
                    >
                      <i className="fa fa-user"></i>
                      {sidebarFlag ? (
                        <span className="nav_name">Users</span>
                      ) : (
                        ""
                      )}
                    </Link>
                  )}
                  <Link
                    key="clients"
                    to="/clients"
                    className={
                      active === "clients" ? "nav_link active" : "nav_link"
                    }
                    onClick={() => setActive("clients")}
                  >
                    <i className="fa fa-building-o"></i>
                    {sidebarFlag ? (
                      <span className="nav_name">Clients</span>
                    ) : (
                      ""
                    )}
                  </Link>
                  <Link
                    key="employees"
                    to="/employees"
                    className={
                      active === "guards" ? "nav_link active" : "nav_link"
                    }
                    onClick={() => setActive("guards")}
                  >
                    <i className="fa fa-user"></i>
                    {sidebarFlag ? (
                      <span className="nav_name">Employees</span>
                    ) : (
                      ""
                    )}
                  </Link>
                  <Link
                    key="sites"
                    to="/sites"
                    className={
                      active === "sites" ? "nav_link active" : "nav_link"
                    }
                    onClick={() => setActive("sites")}
                  >
                    <i className="fa fa-globe"></i>
                    {sidebarFlag ? <span className="nav_name">Sites</span> : ""}
                  </Link>
                  <Link
                    key="all-shifts"
                    to="/all-shifts"
                    className={
                      active === "all-shifts" ? "nav_link active" : "nav_link"
                    }
                    onClick={() => setActive("all-shifts")}
                  >
                    <i className="fa fa-calendar"></i>
                    {sidebarFlag ? (
                      <span className="nav_name">
                        All Shifts <span>({total})</span>
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                  <Link
                    key="unallocated"
                    to="/unassigned"
                    className={
                      active === "unallocated" ? "nav_link active" : "nav_link"
                    }
                    onClick={() => setActive("unallocated")}
                  >
                    <i className="fa fa-battery-empty"></i>
                    {sidebarFlag ? (
                      <span className="nav_name">
                        Unassigned <span>({unassigned})</span>
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                  <Link
                    key="assigned"
                    to="/assigned"
                    className={
                      active === "unconfirmed" ? "nav_link active" : "nav_link"
                    }
                    onClick={() => setActive("assigned")}
                  >
                    <i className="fa fa-battery-full nav_icon"></i>
                    {sidebarFlag ? (
                      <span className="nav_name">
                        Assigned <span>({assigned})</span>
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                  <Link
                    key="confirmed"
                    to="/confirmed"
                    className={
                      active === "confirmed" ? "nav_link active" : "nav_link"
                    }
                    onClick={() => setActive("confirmed")}
                  >
                    <i className="fa fa-check"></i>
                    {sidebarFlag ? (
                      <span className="nav_name">
                        Confirmed <span>({confirmed})</span>
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                  <Link
                    key="cancelled"
                    to="/cancelled"
                    className={
                      active === "cancelled" ? "nav_link active" : "nav_link"
                    }
                    onClick={() => setActive("cancelled")}
                  >
                    <i className="fa fa-trash"></i>
                    {sidebarFlag ? (
                      <span className="nav_name">
                        Cancelled <span>({cancelled})</span>
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                  <Link
                    key="all-bookon"
                    to="/all-bookon"
                    className={
                      active === "all-bookon" ? "nav_link active" : "nav_link"
                    }
                    onClick={() => setActive("all-bookon")}
                  >
                    <i className="fa fa-book"></i>
                    {sidebarFlag ? (
                      <span className="nav_name">
                        All Bookon ({shiftReducer.bookonStats?.total || 0})
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                  <Link
                    key="bookon"
                    to="/bookon"
                    className={
                      active === "bookon" ? "nav_link active" : "nav_link"
                    }
                    onClick={() => setActive("bookon")}
                  >
                    <i className="fa fa-book"></i>
                    {sidebarFlag ? (
                      <span className="nav_name">
                        Pending Bookon ({shiftReducer.bookonStats?.pending || 0}
                        )
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                  <Link
                    key="confirmed-bookon"
                    to="/confirmed-bookon"
                    className={
                      active === "confirmed-bookon"
                        ? "nav_link active"
                        : "nav_link"
                    }
                    onClick={() => setActive("confirmed-bookon")}
                  >
                    <i className="fa fa-book"></i>
                    {sidebarFlag ? (
                      <span className="nav_name">
                        Confirmed Bookon (
                        {shiftReducer.bookonStats?.confirmed || 0})
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                  {JSON.parse(sessionStorage.getItem("user")).role_name ===
                    "superuser" && (
                    <li key="reports">
                      <Link
                        to="#"
                        data-bs-toggle="collapse"
                        data-bs-target="#home-collapse"
                        className={
                          active === "reports" ? "nav_link active" : "nav_link"
                        }
                        onClick={() => {
                          setActive("reports");
                          setArrowDown(!arrowDown);
                        }}
                        aria-expanded="false"
                      >
                        <i className="fa fa-bar-chart"></i>
                        <span className="nav_name">
                          Reports{" "}
                          <i
                            className={
                              arrowDown === true
                                ? "ms-4  nav_name fa fa-angle-down"
                                : "ms-4 nav_name fa fa-angle-up "
                            }
                          ></i>
                        </span>
                      </Link>
                      <div className="collapse " id="home-collapse">
                        <ul className="btn-toggle-nav list-unstyled fw-normal small">
                          <li key="payroll">
                            <Link
                              key="payroll"
                              to="/payroll"
                              className={
                                active === "payroll"
                                  ? "nav_link active"
                                  : "nav_link"
                              }
                              onClick={() => setActive("payroll")}
                            >
                              <i className="fa fa-credit-card"></i>
                              <span className="nav_name">Payroll</span>
                            </Link>
                          </li>
                          <li key="invoice">
                            <Link
                              key="invoice"
                              to="/invoice"
                              className={
                                active === "invoice"
                                  ? "nav_link active"
                                  : "nav_link d-flex"
                              }
                              onClick={() => setActive("invoice")}
                            >
                              <i className="fa fa-money"></i>
                              <span className="nav_name">Invoice</span>
                            </Link>
                          </li>
                          <li key="rota">
                            <Link
                              key="rota"
                              to="/rota"
                              className={
                                active === "rota"
                                  ? "nav_link active"
                                  : "nav_link"
                              }
                              onClick={() => setActive("rota")}
                            >
                              <i className="fa fa-file"></i>
                              <span className="nav_name">ROTA</span>
                            </Link>
                          </li>
                          <li key="bookon-report">
                            <Link
                              key="bookon-report"
                              to="/bookon-report"
                              className={
                                active === "bookon-report"
                                  ? "nav_link active"
                                  : "nav_link"
                              }
                              onClick={() => setActive("bookon-report")}
                            >
                              <i className="fa fa-book"></i>
                              <span className="nav_name">Bookon</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  )}
                  <Link
                    key="chats"
                    to="/chats"
                    className={
                      active === "chats" ? "nav_link active" : "nav_link d-flex"
                    }
                    onClick={() => setActive("chats")}
                  >
                    <i className="fa fa-comment"></i>
                    {sidebarFlag ? <span className="nav_name">Chats</span> : ""}
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default connect(
  ({ userReducer, shiftReducer, clientReducer }) => ({
    userReducer,
    shiftReducer,
    clientReducer,
  }),
  {
    logoutAction,
    getShiftStats,
    getBookonStats,
    getAllClients,
    changeClient,
  }
)(Sidebar);
