import React, { useRef, useState } from "react";
import "./index.scss";
import { useDispatch } from "react-redux";
import { postCheckCalls } from "store/action/shiftAction";
import Modal from "components/Modal";

const HourlyCheckBoxes = ({
  startTime,
  endTime,
  shift,
  check_calls,
  start_date,
}) => {
  const [selectedHours, setSelectedHours] = useState([]);
  const [startHour, startMinute] = startTime.split(":").map(Number);
  const [endHour, endMinute] = endTime.split(":").map(Number);
  const dispatch = useDispatch();
  const [comment, setComment] = useState(false);
  const [id, setId] = useState(false);
  const close = useRef(null);

  function checkTimeDifference(time) {
    const [hour, minute] = time.split(":").map(Number);
    var current = new Date();
    var inputTime = new Date(
      current.getFullYear(),
      current.getMonth(),
      current.getDate(),
      hour,
      minute
    );
    var timeDifference = current - inputTime;
    var absTimeDifference = Math.abs(timeDifference);
    if (absTimeDifference <= 10 * 60 * 1000) {
      return true;
    } else {
      return false;
    }
  }

  const generateHoursArray = () => {
    const startTimeInMinutes = startHour * 60 + startMinute;
    const endTimeInMinutes =
      endHour < startHour || (endHour === startHour && endMinute < startMinute)
        ? (endHour + 24) * 60 + endMinute
        : endHour * 60 + endMinute;

    const hoursArray = [];
    for (let i = startTimeInMinutes; i <= endTimeInMinutes; i += 60) {
      const hour = Math.floor(i / 60) % 24;
      const minute = i % 60;

      hoursArray.push(
        `${hour < 10 ? "0" + hour : hour}:${
          minute < 10 ? "0" + minute : minute
        }`
      );
    }
    return hoursArray;
  };

  const handleCheckboxChange = (e, hour) => {
    const index = selectedHours.indexOf(hour);
    if (index === -1) {
      setSelectedHours([...selectedHours, hour]);
    } else {
      const updatedHours = [...selectedHours];
      updatedHours.splice(index, 1);
      setSelectedHours(updatedHours);
    }
    if (!checkTimeDifference(hour)) {
      document.getElementById("manual-check-calls").click();
      setId(e.target.id);
      let elem = document.querySelector(".comment-box");
      if (elem) {
        elem.dataset.shift = shift;
        elem.dataset.sequence = e.target.id;
      }
    } else {
      dispatch(postCheckCalls({ shift, sequence: parseInt(e.target.id) + 1 }));
    }
  };

  const checkIfMissed = () => {
    const date = new Date(start_date);
    const yesterday = new Date();
    if (date.setHours(0, 0, 0, 0) == yesterday.setHours(0, 0, 0, 0)) {
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      if (date.getTime() < yesterday.getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const renderCheckboxes = () => {
    const hoursArray = generateHoursArray();
    return hoursArray.map((hour, i) => (
      <div
        key={i}
        id={i}
        className={`check-calls justify-content-center ${
          check_calls.find((c) => c.sequence === i + 1)
            ? "checked"
            : checkIfMissed()
            ? "missed"
            : ""
        }`}
        style={{ minWidth: "40px", padding: "2px" }}
        onClick={(e) => handleCheckboxChange(e, hour)}
      >
        <div className="" id={i}>{`${hour}`}</div>
      </div>
    ));
  };

  const closeModal = () => {
    if (document.getElementById("check-calls")) {
      document.getElementById("check-calls").reset();
    }
    document.querySelector(".btn-close").click();
  };

  return (
    <>
      <div className="d-flex">{renderCheckboxes()}</div>
      <button
        className="btn btn-primary d-none"
        id="manual-check-calls"
        data-bs-toggle="modal"
        data-bs-target="#check-calls-modal"
      ></button>
      <Modal
        title="Please add a comment why check call got delayed!"
        id="check-calls-modal"
      >
        <form
          id="check-calls"
          className="p-2"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="form-group mt-2  ">
            <label className="font-weight-bold">comments:</label>
            <textarea
              className="form-control comment-box"
              name="comment"
              onChange={(e) => {
                setComment(e.target.value);
              }}
              required
            />
          </div>
          <div className="d-flex justify-content-center mt-2">
            <button
              className="btn btn-warning"
              onClick={(e) => {
                let commentBox =
                  e.target.parentElement?.previousElementSibling?.querySelector(
                    ".comment-box"
                  );
                if (commentBox) {
                  dispatch(
                    postCheckCalls({
                      shift: parseInt(commentBox?.dataset?.shift),
                      sequence: parseInt(commentBox?.dataset?.sequence) + 1,
                      comment: comment,
                    })
                  );
                  closeModal();
                }
              }}
              // disabled={!(body?.actual_start_time && body?.comment)}
            >
              Check
            </button>
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={() => closeModal()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-warning ms-2"
              data-bs-dismiss="modal"
              ref={close}
              onClick={() => closeModal()}
              hidden={true}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default HourlyCheckBoxes;
