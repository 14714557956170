import { updateObject } from "utils/utility";
import { getError } from "utils/utility";

const initialState = {
  isLoggedIn: false,
  error: "",
  isRetrieving: false,
  isUsersListUpToDate: false,
  loading: false,
  token: "",
  usersList: [],
  isUpdating: false,
  isUserAdded: false,
  closeModal: false,
  isTokenSent: false,
  isPasswordChanged: false,
  isChanging: false,
  rolesList: [],
  isRolesUpToDate: false,
};

if (sessionStorage.getItem("login_token")) {
  initialState.isLoggedIn = true;
  initialState.token = sessionStorage.getItem("token");
}
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "USER_LOGIN":
      return updateObject(state, { loading: true, error: "" });
    case "USER_LOGIN_SUCCESS":
      sessionStorage.setItem("login_token", action.response.token);
      sessionStorage.setItem("user", JSON.stringify(action.response.user));
      return updateObject(state, {
        isLoggedIn: true,
        error: "",
        loading: false,
      });
    case "USER_LOGIN_FAILED":
      return updateObject(state, {
        isLoggedIn: false,
        loading: false,
        error: getError(action.response),
      });
    case "USER_LOGOUT_SUCCESS":
      window.sessionStorage.clear();
      return updateObject(state, { isLoggedIn: false, user: {} });
    case "GET_USER_LIST":
      return updateObject(state, {
        isRetrieving: true,
      });
    case "GET_USER_LIST_SUCCESS":
      return updateObject(state, {
        isRetrieving: false,
        isRolesUpToDate: false,
        usersList: action.response,
        isUsersListUpToDate: true,
      });
    case "GET_USER_LIST_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        isUsersListUpToDate: true,
      });
    case "ADD_USER":
      return updateObject(state, {
        isUpdating: true,
      });
    case "ADD_USER_SUCCESS":
      return updateObject(state, {
        isUpdating: false,
        isUsersListUpToDate: false,
        closeModal: true,
      });
    case "ADD_USER_FAILED":
      return updateObject(state, {
        isUpdating: false,
        isUsersListUpToDate: false,
        closeModal: false,
      });
    case "UPDATE_USER":
      return updateObject(state, {
        isUpdating: true,
      });
    case "UPDATE_USER_SUCCESS":
      return updateObject(state, {
        isUpdating: false,
        isUsersListUpToDate: false,
        closeModal: true,
      });
    case "UPDATE_USER_FAILED":
      return updateObject(state, {
        isUpdating: false,
        isUsersListUpToDate: false,
        closeModal: false,
      });
    case "DELETE_USER":
      return updateObject(state, {
        isUpdating: true,
      });
    case "DELETE_USER_SUCCESS":
      return updateObject(state, {
        isUpdating: false,
        isUsersListUpToDate: false,
      });
    case "DELETE_USER_FAILED":
      return updateObject(state, {
        isUpdating: false,
        isUsersListUpToDate: false,
        closeModal: false,
      });
    case "SEND_TOKEN":
      return updateObject(state, {
        isUpdating: true,
      });
    case "SEND_TOKEN_SUCCESS":
      return updateObject(state, {
        message: action.response,
        isTokenSent: true,
        isUpdating: false,
      });
    case "SEND_TOKEN_FAILED":
      return updateObject(state, {
        error: action.response,
        isUpdating: false,
      });
    case "FORGOT_PASSWORD_SUCCESS":
      return updateObject(state, {
        message: action.response,
      });
    case "FORGOT_PASSWORD_FAILED":
      return updateObject(state, {
        error: action.response,
      });
    case "CHANGE_PASSWORD":
      return updateObject(state, {
        isUpdating: true,
      });
    case "CHANGE_PASSWORD_SUCCESS":
      return updateObject(state, {
        isPasswordChanged: true,
        token: action.response,
      });

    case "CHANGE_PASSWORD_FAILED":
      return updateObject(state, {
        isPasswordChanged: false,
        isUpdating: false,
        error: action.response,
      });
    case "GET_ROLES_LIST":
      return updateObject(state, {
        isRetrieving: true,
      });
    case "GET_ROLES_LIST_SUCCESS":
      return updateObject(state, {
        isRetrieving: false,
        isRolesUpToDate: true,
        rolesList: action.response,
      });

    case "GET_ROLES_LIST_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        isRolesUpToDate: false,
        error: action.response,
      });
    case "RESET_CLOSE_MODAL":
      return updateObject(state, {
        closeModal: false,
      });
    case "CONNECTION_REFUSED":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
      });
    default:
      return state;
  }
}
