import { updateObject, getError } from "utils/utility";

const initialState = {
  message: "",
  error: "",
};

export default function alerts(state = initialState, action) {
  switch (action.type) {
    case "CONNECTION_REFUSED":
      return updateObject(state, {
        error:
          "Something went wrong while performing the action. Please try again and if the problem persists contact system admin",
      });
    case "CREATE_GUARD_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Employee Created Successfully!!!",
          })
        : state;
    case "UPDATE_GUARD_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Employee Updataed Successfully!!",
          })
        : state;
    case "DELETE_GUARD_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Employee Deleted Successfully!!",
          })
        : state;
    case "CREATE_SITE_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Site Created Successfully!!",
          })
        : state;
    case "UPDATE_SITE_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Site Updated Successfully!!",
          })
        : state;
    case "CREATE_CLIENT_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Client Created Successfully",
          })
        : state;
    case "UPDATE_CLIENT_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Client Updated Successfully",
          })
        : state;
    case "DELETE_CLIENT_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Client Deleted Successfully!!",
          })
        : state;
    case "DELETE_SITE_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Site Deleted Successfully!!",
          })
        : state;
    case "CREATE_SHIFT_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Shift Created Successfully",
          })
        : state;
    case "UPDATE_SHIFT_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Shift Updated Successfully!!",
          })
        : state;
    case "DELETE_SHIFT_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Shift Deleted Successfully!!",
          })
        : state;
    case "ADD_USER_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "User Added Successfully!!",
          })
        : state;
    case "DELETE_USER_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "User Deleted Successfully!!",
          })
        : state;
    case "ALLOCATE_SHIFT_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Changed Shift Status Successfully!!",
          })
        : state;
    case "FORGOT_PASSWORD_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Password Reset Successfully!!",
          })
        : state;
    case "SEND_TOKEN_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Token Sent Successfully!!",
          })
        : state;
    case "CHANGE_PASSWORD_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Password Changed Successfully!!",
          })
        : state;
    case "SEND_EMAIL_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "Email Sent To All Active Employees!!",
          })
        : state;
    case "UPDATE_USER_SUCCESS":
      return action.response
        ? updateObject(state, {
            message: "User Updated Successfully",
          })
        : state;
    case "CREATE_GUARD_FAILED":
    case "UPDATE_GUARD_FAILED":
    case "CREATE_CLIENT_FAILED":
    case "UPDATE_SITE_FAILED":
    case "UPDATE_CLIENT_FAILED":
    case "DELETE_GUARD_FAILED":
    case "ALLOCATE_SHIFT_FAILED":
    case "DELETE_CLIENT_FAILED":
    case "DELETE_SITE_FAILED":
    case "CREATE_SHIFT_FAILED":
    case "DELETE_SHIFT_FAILED":
    case "SEND_EMAIL_FAILED":
    case "UPDATE_SHIFT_FAILED":
    case "CREATE_SITE_FAILED":
    case "ADD_USER_FAILED":
    case "SEND_TOKEN_FAILED":
    case "FORGOT_PASSWORD_FAILED":
    case "CHANGE_PASSWORD_FAILED":
    case "DELETE_USER_FAILED":
    case "UPDATE_USER_FAILED":
      return action.response
        ? updateObject(state, {
            error: getError(action.response),
          })
        : state;
    case "RESET_ALERTS":
      return updateObject(state, { message: "", error: "" });
    default:
      return state;
  }
}
