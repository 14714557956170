import Alert from "components/Alert";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { resetCloseModal } from "store/action/alerts";
import { createUser, updateUser, getRoles } from "store/action/user";

const UserForm = ({
  userReducer,
  data,
  isUpdating,
  updateUser,
  createUser,
  getRoles,
  resetCloseModal,
}) => {
  const form = useRef(null);
  let close = useRef(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [displayError, setDisplayError] = useState(false);

  const resetForms = () => {
    document.getElementById("newUserForm").reset();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formdata = new FormData(form.current);
    !isUpdating && formdata.append("role", 2);
    isUpdating
      ? updateUser(data.id, formdata, "formdata")
      : createUser(formdata, "formdata");
  };

  useEffect(() => {
    if (!userReducer.isRolesUpToDate) getRoles();
  }, [userReducer.isRolesUpToDate, getRoles]);

  useEffect(() => {
    if (userReducer.closeModal) {
      close.click();
      resetForms();
      resetCloseModal();
    }
  }, [userReducer.closeModal, resetCloseModal]);

  const confirm = e => {
    setDisplayError(true);
    setConfirmPassword(e.target.value);
  };
  return (
    <div>
      <Alert />
      <form
        id="newUserForm"
        ref={form}
        className="p-2"
        onSubmit={e => handleSubmit(e)}>
        <div className="form-group mt-2  ">
          <label htmlFor="name" className="font-weight-bold">
            User Name :
          </label>
          <input
            type="text"
            className="form-control"
            id="email"
            placeholder="User Name"
            defaultValue={data.email}
            name="email"
            required
          />
        </div>
        <div className="form-group mt-2  ">
          <label htmlFor="name" className="font-weight-bold">
            User Role<span className="req">*</span> :
          </label>
          <select
            type="text"
            className="form-control"
            id="role"
            placeholder="Full Name"
            name="role"
            required>
            <option value={0} key={0}>
              --Select From The List --
            </option>
            {data.role && (
              <option value={data.role} key={data.role} selected>
                {data.role_name}
              </option>
            )}
            {userReducer?.rolesList?.map(obj => {
              return (
                <>
                  <option value={obj.id} key={obj.id} label={obj.name}>
                    {obj.name}
                  </option>
                </>
              );
            })}
          </select>
        </div>
        <div className="form-group mt-2  ">
          <label htmlFor="name" className="font-weight-bold">
            Password :
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            onChange={e => setPassword(e.target.value)}
            placeholder="password"
            name="password"
          />
        </div>
        <div className="form-group mt-2  ">
          <label htmlFor="name" className="font-weight-bold">
            Confirm Password :
          </label>
          <input
            type="password"
            className="form-control"
            id="confirm_password"
            placeholder="Confirm Password"
            name="confirm_password"
            onChange={e => confirm(e)}
          />
        </div>
        {password !== confirmPassword && displayError && (
          <div className="text-danger">Passwords do not Match</div>
        )}
        <div className="d-flex justify-content-center mt-2">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={password !== confirmPassword && displayError}>
            {userReducer.isUpdating ? (
              <>
                <i className="fa fa-spinner fa-spin" />
                <span> Processing...</span>
              </>
            ) : isUpdating ? (
              <span>Update User</span>
            ) : (
              <span>Save User</span>
            )}
          </button>
          <button
            type="button"
            className="btn btn-warning ms-2"
            data-bs-dismiss="modal"
            ref={button => (close = button)}
            onClick={() => resetForms()}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default connect(({ userReducer }) => ({ userReducer }), {
  createUser,
  updateUser,
  getRoles,
  resetCloseModal,
})(UserForm);
