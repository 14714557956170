import React from "react";
import gif from "static/images/spinner.gif";
import styles from "./Spinner.module.scss";

const Spinner = () => {
  return (
    <div className={`${styles.spinner}`}>
      <img src={gif} alt="loading" />
    </div>
  );
};

export default Spinner;
