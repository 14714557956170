import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { forgotPassword } from "store/action/user";
import NurlineLogo from "static/images/tkl_green_logo.jpg";
import Alert from "components/Alert";

const ForgotPassword = ({ forgotPassword, userReducer }) => {
  const [email, setEmail] = useState("");

  const onsubmit = (e) => {
    e.preventDefault();
    forgotPassword({ email });
  };

  return (
    <>
      <div className="body-pd" id="body-pd">
        <header className="body-pd header" id="header">
          <div className="header_img">
            <img src={NurlineLogo} alt="" />
          </div>
        </header>
      </div>
      <div className="col-xl-12 col-12">
        <Alert />

        <div className="forgot-password">
          <div className="card text-center">
            {!userReducer.isTokenSent ? (
              <div className="card-body">
                <h5 className="ml-0 mt-4 text-center">
                  Forgot Password? Dont worry!
                </h5>
                <h6 className="mb-0 text-center">
                  Enter your email id and you can change password in your email
                  inbox
                </h6>

                <form onSubmit={(e) => onsubmit(e)}>
                  <input
                    type="text"
                    className="form-control mb-2 mt-2"
                    placeholder="Email"
                    required
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <button
                    className="btn btn-primary btn-block mb-1"
                    type="submit"
                    disabled={userReducer.isUpdating}
                  >
                    {userReducer.isUpdating ? (
                      <>
                        <i className="fa fa-spinner fa-spin" />
                        <span> Processing...</span>
                      </>
                    ) : (
                      <>
                        <i className="fa fa-paper-plane mr-2" />
                        <span>Send Email</span>
                      </>
                    )}
                  </button>
                </form>
              </div>
            ) : (
              <div className="card-body">
                <h5 className="ml-0 mt-4 text-center">Success!!!!</h5>
                <h6 className="mb-0 text-center">
                  Please Visit your inbox . We sent reset password link to your
                  email id.
                </h6>
                <h7 className="mb-0 text-center">
                  If No Email Recieved Please check and enter your emailId
                  correctly.
                </h7>

                <h7 className="mb-0 text-center">
                  <Link to={`/`}>Click here to go to login page.</Link>
                </h7>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(({ userReducer }) => ({ userReducer }), {
  forgotPassword,
})(ForgotPassword);
