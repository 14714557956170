import React from "react";
import { Bar } from "react-chartjs-2";
import { CategoryScale, Chart, LinearScale, BarElement } from "chart.js";

const Stats = ({ stats }) => {
  const {
    hours_last_week,
    hours_this_week,
    last_week_shifts,
    confirmed,
    monday,
    sunday,
    last_week_monday,
    last_week_sunday,
  } = stats || {};

  const HrsData = {
    labels: [last_week_monday],
    datasets: [
      {
        label: `Last week Hours Confirmed `,
        data: [hours_last_week],
        backgroundColor: ["#0090d9"],
      },
      {
        label: `This week Hours Confirmed `,
        data: [hours_this_week],
        backgroundColor: ["#0aa699"],
      },
      {
        label: `Last week Shifts Confirmed `,
        data: [last_week_shifts],
        backgroundColor: ["#f35958"],
      },
      {
        label: `This week Shifts Confirmed`,
        data: [confirmed],
        backgroundColor: ["#735f87"],
      },
    ],
  };

  Chart.register(CategoryScale);
  Chart.register(LinearScale);
  Chart.register(BarElement);

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="col-6">
          <Bar data={HrsData} options={{ maintainAspectRatio: true }}></Bar>
        </div>
      </div>
      <div className="d-flex justify-content-between col-12 flex-column flex-md-row flex-gap">
        <div className="card bg-first text-white  w-100">
          <div className="card-body">
            <div className="d-flex flex-column">
              <span className="fs-6">LAST WEEK HOURS</span>
              <span className="fs-6">Confirmed</span>
              <span className="fs-3 mt-3">{hours_last_week || 0} HOURS</span>
              <span className="fs-6">
                {last_week_monday} - {last_week_sunday}
              </span>
            </div>
          </div>
        </div>
        <div className="card  bg-second text-white w-100">
          <div className="card-body">
            <div className="d-flex  flex-column">
              <span className="fs-6">THIS WEEK HOURS</span>
              <span className="fs-6">Confirmed</span>
              <span className="fs-3 mt-3">{hours_this_week || 0} HOURS</span>
              <span className="fs-6">
                {monday} - {sunday}
              </span>
            </div>
          </div>
        </div>
        <div className="card  bg-third text-white w-100">
          <div className="card-body">
            <div className="d-flex flex-column">
              <span className="fs-6">LAST WEEK SHIFTS</span>
              <span className="fs-6">Confirmed</span>
              <span className="fs-3 mt-3">{last_week_shifts} SHIFTS</span>
              <span className="fs-6">
                {last_week_monday} - {last_week_sunday}
              </span>
            </div>
          </div>
        </div>

        <div className="card  bg-fourth text-white w-100">
          <div className="card-body">
            <div className="d-flex flex-column">
              <span className="fs-6">THIS WEEK SHIFTS</span>
              <span className="fs-6">Confirmed</span>
              <span className="fs-3 mt-3">{confirmed} SHIFTS</span>
              <span className="fs-6">
                {monday} - {sunday}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stats;
