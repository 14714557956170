import { updateObject } from "utils/utility";
import { getError } from "utils/utility";

const initialState = {
  isShiftUpToDate: false,
  closeModal: false,
  loading: false,
  isRetrieving: false,
  isAllocated: false,
  stats: {},
  bookonStats: {},
  statsUpToDate: false,
  shiftList: [],
  eventsList: [],
  site_details: [],
  todayShifts: [],
  shiftLogs: [],
  fetchingTodayShifts: false,
  shiftDetails: {},
  error: "",
  date_array: [],
  bookonShiftList: [],
  isBookonShiftListUpToList: false,
};

export default function shiftReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_SHIFTS":
      return updateObject(state, {
        loading: true,
        isRetrieving: true,
        error: "",
      });
    case "GET_ALL_SHIFTS_SUCCESS":
      return updateObject(state, {
        isShiftUpToDate: true,
        isRetrieving: false,
        shiftList: action.response,
        isAllocated: false,
        error: "",
        shiftDetails: {},
        shiftLogs: [],
        loading: false,
        closeModal: false,
      });
    case "GET_ALL_SHIFTS_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
        error: getError(action.response),
      });
    case "CREATE_SHIFT":
      return updateObject(state, {
        loading: true,
        isRetrieving: true,
        error: "",
      });
    case "CREATE_SHIFT_SUCCESS":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
        isShiftUpToDate: false,
        closeModal: true,
        error: "",
      });
    case "CREATE_SHIFT_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
        error: getError(action.response),
      });
    case "UPDATE_SHIFT":
      return updateObject(state, {
        loading: true,
        isShiftUpToDate: false,
        isRetrieving: true,
        error: "",
      });
    case "UPDATE_SHIFT_SUCCESS":
      return updateObject(state, {
        isRetrieving: false,
        isShiftUpToDate: true,
        isBookonShiftListUpToList: false,
        closeModal: true,
        loading: false,
        error: "",
      });
    case "UPDATE_SHIFT_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        isBookonShiftListUpToList: false,
        loading: false,
        error: getError(action.response),
      });
    case "GET_SHIFT_STATS_SUCCESS":
      return updateObject(state, {
        stats: action.response,
        statsUpToDate: true,
      });
    case "GET_BOOKON_STATS_SUCCESS":
      return updateObject(state, {
        bookonStats: action.response,
        statsUpToDate: true,
      });
    case "SET_CLIENT":
      return updateObject(state, {
        statsUpToDate: false,
      });
    case "ALLOCATE_SHIFT":
      return updateObject(state, {
        isRetrieving: true,
        error: "",
      });
    case "ALLOCATE_SHIFT_SUCCESS":
      return updateObject(state, {
        isRetrieving: false,
        statsUpToDate: false,
        isAllocated: true,
        isShiftUpToDate: !state.isShiftUpToDate,
        error: "",
      });
    case "ALLOCATE_SHIFT_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        error: getError(action.response),
      });
    case "DELETE_SHIFT":
      return updateObject(state, {
        loading: true,
        isRetrieving: true,
        error: "",
      });
    case "DELETE_SHIFT_SUCCESS":
      return updateObject(state, {
        isRetrieving: false,
        isAllocated: true,
        isShiftUpToDate: !state.isShiftUpToDate,
        error: "",
      });
    case "DELETE_SHIFT_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        error: getError(action.response),
      });
    case "SET_EVENTS": {
      return updateObject(state, {
        isShiftUpToDate: true,
        eventsList: action.paylaod.events,
        site_details: action.paylaod.site_details,
        date_array: action.paylaod.date_array,
        time_array: action.paylaod.time_array,
        time_finish_array: action.paylaod.time_finish_array,
      });
    }
    case "GET_TODAY_SHIFTS":
      return updateObject(state, {
        fetchingTodayShifts: true,
      });
    case "GET_TODAY_SHIFTS_SUCCESS":
      return updateObject(state, {
        fetchingTodayShifts: false,
        todayShifts: action.response,
      });
    case "GET_TODAY_SHIFTS_FAILED":
      return updateObject(state, {
        fetchingTodayShifts: false,
      });
    case "GET_SHIFT_BY_ID":
      return updateObject(state, {
        isRetrieving: true,
      });
    case "GET_SHIFT_BY_ID_SUCCESS":
      return updateObject(state, {
        isRetrieving: false,
        shiftDetails: action.response,
      });
    case "GET_SHIFT_BY_ID_FAILED":
      return updateObject(state, {
        isRetrieving: false,
      });
    case "GET_SHIFT_LOGS":
      return updateObject(state, {
        isRetrieving: true,
      });
    case "GET_SHIFT_LOGS_SUCCESS":
      return updateObject(state, {
        isRetrieving: false,
        shiftLogs: action.response,
        isShiftUpToDate: true,
      });
    case "GET_SHIFT_LOGS_FAILED":
      return updateObject(state, {
        isRetrieving: false,
        isShiftUpToDate: false,
      });

    case "RESET_CLOSE_MODAL":
      return updateObject(state, {
        closeModal: false,
      });
    case "CONNECTION_REFUSED":
      return updateObject(state, {
        isRetrieving: false,
        loading: false,
      });
    case "GET_Bookon_SHIFTS":
      return updateObject(state, {
        loading: true,
        error: "",
      });
    case "GET_Bookon_SHIFTS_SUCCESS":
      return updateObject(state, {
        bookonShiftList: action.response,
        loading: false,
        isBookonShiftListUpToList: true,
      });
    case "GET_Bookon_SHIFTS_FAILED":
      return updateObject(state, {
        loading: false,
        error: getError(action.response),
      });

    case "POST_CHECK_CALLS_SUCCESS":
      return updateObject(state, {
        isBookonShiftListUpToList: false,
      });
    case "RESET_BOOKONS":
      return updateObject(state, {
        isBookonShiftListUpToList: false,
      });

    default:
      return state;
  }
}
