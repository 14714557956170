import Modal from "components/Modal";
import React, { useState } from "react";
import DetailedPayroll from "./detailedPayroll";
import Grid from "components/DataTables";

const ReportGrid = ({ reportReducer, isInvoice, exportAll, isGuest }) => {
  const [data, setData] = useState({});

  const actionRenderer = ({ data }) => {
    return (
      <>
        <button
          data-bs-toggle="modal"
          data-bs-target="#commonModal"
          className="btn btn-primary ms-2"
          onClick={() => {
            setData(data);
          }}
        >
          View Details
        </button>
      </>
    );
  };

  const payRollColumns = {
    columnDefs: [
      { headerName: "#", valueGetter: "node.rowIndex + 1", sortable: true },
      {
        headerName: "Staff Name",
        field: "full_name",
        sortable: true,
      },
      { headerName: "Total Hours", field: "total_hours" },
      { headerName: "Total Allowance", field: "total_allowance", width: 190 },
      { headerName: "Total Pay", field: "total_pay" },
      { headerName: "Account Number", field: "account_number" },
      { headerName: "Sort Code", field: "sort_code" },
      { headerName: "Name on account", field: "name_on_account", flex: 1 },
      {
        headerName: "Actions",
        cellRenderer: actionRenderer,
        width: 150,
        pinned: "right",
        flex: 1,
      },
    ],
  };

  const invoiceColumns = {
    columnDefs: [
      { headerName: "#", valueGetter: "node.rowIndex + 1", sortable: true },
      {
        headerName: "Site Name",
        field: "site_name",
        sortable: true,
        width: 500,
      },
      { headerName: "Total Hours", field: "total_hours" },
      { headerName: "Total Pay", field: "total_pay", flex: 1 },
      {
        headerName: "Actions",
        cellRenderer: actionRenderer,
        width: 150,
        pinned: "right",
      },
    ],
  };

  return (
    <div>
      <Modal>
        <DetailedPayroll data={data} isInvoice={isInvoice} />
      </Modal>
      <div className="card ">
        <div className="card-header">
          <h4 className="text-center">{isInvoice ? "Invoice" : "Payroll"}</h4>
        </div>
        <div className="card-body">
          <Grid
            columnDefs={isInvoice ? invoiceColumns : payRollColumns}
            rowData={
              isInvoice
                ? reportReducer.invoiceList.data
                : isGuest
                ? reportReducer.guestPayrollList.data
                : reportReducer.payrollList.data
            }
            dataRetrieved={!reportReducer.isRetrieving}
            exportAll={exportAll}
          ></Grid>
        </div>
      </div>
    </div>
  );
};
export default ReportGrid;
